import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.css';

import newYorkImage from './images/location_new_york.jpg';
import mumbaiImage from './images/location_mumbai.jpg';
import sanDiegoImage from './images/location_san_diego.jpg';
import chicagoImage from './images/location_chicago.jpg';
import losAngelesImage from './images/location_los_angeles.jpg';
import tokyoImage from './images/location_tokyo.jpg';
import londonImage from './images/location_london.jpg';
import buenosAiresImage from './images/location_buenos_aires.jpg';
import berlinImage from './images/location_berlin.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}

const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery, num) => {

  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id={`SectionLocations.listingsInLocation${num}`}
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const locationsItemsArray1 = [
  locationLink(
    'New York',
    newYorkImage,
    '?address=New%20York%2C%20NY%2C%20USA&bounds=40.9175771%2C-73.70027209999999%2C40.4773991%2C-74.25908989999999',
    1
  ),
  // locationLink(
  //   'Chicago',
  //   chicagoImage,
  //   '?address=Chicago%2C%20IL%2C%20USA&bounds=42.023131%2C-87.52366099999999%2C41.6443349%2C-87.9402669',
  //   2
  // ),
  locationLink(
    'Berlin',
    berlinImage,
    '?address=Berlin%2C%20Germany&bounds=52.6754542%2C13.7611175%2C52.338234%2C13.088346',
    3
  ),
]
const locationsItemsArray2 = [
  locationLink(
    'Los Angeles',
    losAngelesImage,
    '?address=Los%20Angeles%2C%20CA%2C%20USA&bounds=34.48306191%2C-117.73520428%2C33.51305145%2C-118.76691062',
    1
  ),
  // locationLink(
  //   'Mumbai',
  //   mumbaiImage,
  //   '?address=Mumbai%2C%20Maharashtra%2C%20India&bounds=19.2716339%2C72.9864994%2C18.8928676%2C72.7758729',
  //   2
  // ),
  // locationLink(
  //   'Tokyo',
  //   tokyoImage,
  //   '?address=Tokyo%2C%20Japan&bounds=35.8174453%2C139.9188743%2C35.519042%2C139.5628611',
  //   3
  // ),
]
const locationsItemsArray3 = [
  locationLink(
    'London',
    londonImage,
    '?address=London%2C%20UK&bounds=51.6723432%2C0.148271%2C51.38494009999999%2C-0.3514683',
    1
  ),
  // locationLink(
  //   'Buenos Aires',
  //   buenosAiresImage,
  //   '?address=Buenos%20Aires%2C%20Argentina&bounds=-34.5265464%2C-58.33514470000001%2C-34.7051011%2C-58.5314522',
  //   2
  // ),
  locationLink(
    'San Diego',
    sanDiegoImage,
    '?address=San%20Diego%2C%20CA%2C%20USA&bounds=33.114249%2C-116.90816%2C32.534856%2C-117.3097969',
    3
  ),
]

const locationsRandomItem1 = Math.floor( (Math.random() * locationsItemsArray1.length) + 0);
const locationsRandomItem2 = Math.floor( (Math.random() * locationsItemsArray2.length) + 0);
const locationsRandomItem3 = Math.floor( (Math.random() * locationsItemsArray3.length) + 0);

const sectionLocationsBlock1 = locationsItemsArray1[locationsRandomItem1];
const sectionLocationsBlock2 = locationsItemsArray2[locationsRandomItem2];
const sectionLocationsBlock3 = locationsItemsArray3[locationsRandomItem3];

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {sectionLocationsBlock1}
        {sectionLocationsBlock2}
        {sectionLocationsBlock3}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
