/**
 * A text field with phone number formatting. By default uses formatting
 * rules defined in the fiFormatter.js file. To change the formatting
 * provide alternative implementations for the format and parse functions
 * that are passed to the input field.
 */
 import React, { useState } from 'react';
 import PhoneInput from 'react-phone-number-input'
 import { Field } from 'react-final-form';
 
 const SignupPhoneNumberComponent = ({ input, displayInitialValueAsLocalNumber }) =>  {
 
   return(
     <PhoneInput
       displayInitialValueAsLocalNumber={displayInitialValueAsLocalNumber}
       international
       defaultCountry="US"
       value={input.value}
       onChange={value => input.onChange(value)}
       placeholder='+1 917 000 0000'
       name='phoneNumber'
       maxLength={17}
     />
   )
 }
 
 const FieldCustomPhoneNumberInput = props => {
   return (
     <Field
       name={props.name}
       component={SignupPhoneNumberComponent}
       {...props}
     />
   );
 };
 
 export default FieldCustomPhoneNumberInput;
 