import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-new-york',
    predictionPlace: {
      address: 'New York, USA',
      bounds: new LatLngBounds(
        new LatLng(41.64780643, -71.70494872),
        new LatLng(39.81437008, -74.7605937)
      ),
    },
  },
  {
    id: 'default-los-angeles',
    predictionPlace: {
      address: 'Los Angeles, USA',
      bounds: new LatLngBounds(
        new LatLng(35.07981368,-117.04617016), 
        new LatLng(33.07579428,-119.10181514)
      ),
    },
  },
  {
    id: 'default-london',
    predictionPlace: {
      address: 'London, UK',
      bounds: new LatLngBounds(
        new LatLng(51.83572917,0.37743375), 
        new LatLng(51.08191,-0.65038875)
      ),
    },
  },
  // {
  //   id: 'default-mumbai',
  //   predictionPlace: {
  //     address: 'Mumbai, India',
  //     bounds: new LatLngBounds(
  //       new LatLng(19.1250879,72.8874965), 
  //       new LatLng(19.0214815,72.8341841)
  //     ),
  //   },
  // },
  {
    id: 'default-berlin',
    predictionPlace: {
      address: 'Berlin, Germany',
      bounds: new LatLngBounds(
        new LatLng(52.89140717,13.89538479), 
        new LatLng(52.15529893,12.8675623)
      ),
    },
  },
];
