import React from 'react';
import { compose } from 'redux';
import { StaticPage, TopbarContainer } from '../../containers';
import ReactPlayer from 'react-player';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { withViewport } from '../../util/contextHelpers';

import css from './AboutPage.css';
import image from './about-us-1056.jpg';
import videoUrl from './Local_FnF_introduction_video.mp4';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const AboutPageComponent = props => {
  const isMobileLayout = props.viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const videoWidth = isMobileLayout ? props.viewport.width - 52 : 570;
  const videoHeight = isMobileLayout ? props.viewport.width - 168 : 320;

  // prettier-ignore
  return (
    <StaticPage
      title="About Us | Localfnf"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About LocalFnF',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AboutPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>About LocalFnF</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p>
                LocalFnF creates a marketplace where people can connect through distinct experiences
                and generate income by hosting events.
              </p>
              <p>
                This is a platform where people can interact and learn about new cultures, ethnicity
                and related experiences. A safe market place where you have the freedom to host and
                create events at your own pace and resources.
              </p>
              <p>
                Through LocalFnF you will be able to learn about your host or guest before you meet
                them. Users have the option to create an introductory video so you can learn more
                about your new friends.
              </p>
              <p>
                LocalFnF is a way to find new friends locally or anywhere in the world. Helps you
                create lasting friendships and reinvent yourself with new experiences.
              </p>
            </div>
            {/* <img className={css.coverImage} src={image} alt="About LocalFnF" /> */}
            <div className={css.videoContainer}>
              <ReactPlayer url={videoUrl} width={videoWidth} height={videoHeight} controls={true} />
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const AboutPage = compose(withViewport)(AboutPageComponent);

export default AboutPage;
