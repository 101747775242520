import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { FavoriteListingCard } from '../../components';

import css from './SectionFavorite.css';

const SectionFavorite = props => {
  const {
    rootClassName,
    className,
    onUpdateProfile,
    currentUser,
    listingFavourite
  } = props;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const classes = classNames(rootClassName || css.root, className);
  const favoritesList = currentUserLoaded ? currentUser.attributes.profile.publicData.listingFavourite : [];

  const favoritesListings = favoritesList === undefined || favoritesList.length === 0  ? (
    <div className={classes}>
      <div className={css.subTitle}>
        <FormattedMessage id="FavoritesPage.noListingsTitle" />
      </div>
    </div>
  ) : (
    <div className={classes}>
      <div className={css.sectionFavoriteBlock} >
        {favoritesList.map(l =>
          <FavoriteListingCard
            currentUser={currentUser}
            onUpdateProfile={onUpdateProfile}
            listing={l}
            key={l.listingId}
          />
        )}
      </div>
    </div>
  );


  return (
    <>
      {favoritesListings}
    </>
  );
};

SectionFavorite.defaultProps = {
  listings: [],
  rootClassName: null,
  className: null,
  currentUser: null
};

const { string } = PropTypes;

SectionFavorite.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFavorite;
