import React, { useState, useEffect } from 'react';
import { bool, func, array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingItineraryTable.css';

const ACCEPTED_NAME = 'transition/accept';

const ListingItineraryTable = props => {
  const { transactions, activelabelId, onCancelClick, onActivateTransaction } = props;

  const tabledataHeader = [
    '№',
    'Date',
    'Time',
    'Listing name',
    'Price/guest',
    'Guests',
    'In total',
  ];

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      {transactions && transactions.length > 0 ? (
        windowDimensions < 1024 ? (
          <div className={css.cardMobile}>
            {transactions.map((transaction, index) => {
              const price = (transaction.attributes.lineItems[0].unitPrice.amount / 100).toFixed(2);
              const seats = transaction.attributes.lineItems[0].seats;
              const units = parseInt(transaction.attributes.lineItems[0].units.value);
              const inTotal = (transaction.attributes.payinTotal.amount / 100).toFixed(2);
              const date = new Date(transaction.attributes.protectedData.start);
              const time = new Date(transaction.attributes.protectedData.start).toLocaleTimeString(
                'en-US',
                {
                  hour: '2-digit',
                  minute: '2-digit',
                }
              );
              const bookingDate = `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;
              const name = transaction.attributes.protectedData.listingName;
              const guest = seats;
              const transactionId = transaction.id;
              const isLastTransitionAccept =
                transaction.attributes.lastTransition === ACCEPTED_NAME;
              const listingId = transaction.relationships.listing.data.id;
              const cancellationPolicy = transaction.attributes.protectedData.cancellationPolicy
                ? transaction.attributes.protectedData.cancellationPolicy
                : 'medium';

              const isActive = activelabelId ? activelabelId.uuid === listingId.uuid : false;

              return (
                <div
                  className={isActive ? css.cardContainerMobileActive : css.cardContainerMobile}
                  key={index}
                  onMouseEnter={() => {
                    onActivateTransaction(listingId);
                  }}
                  onMouseLeave={() => {
                    onActivateTransaction(null);
                  }}
                >
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[0]}</span>
                    <span className={css.itemSecondColumnMobile}>{index + 1}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[1]}</span>
                    <span className={css.itemSecondColumnMobile}>{bookingDate}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[2]}</span>
                    <span className={css.itemSecondColumnMobile}>{time}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[3]}</span>
                    <span className={css.itemSecondColumnMobile}>{name}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[4]}</span>
                    <span className={css.itemSecondColumnMobile}>{`${price * units}$`}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[5]}</span>
                    <span className={css.itemSecondColumnMobile}>{guest}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[6]}</span>
                    <span className={css.itemSecondColumnMobile}>{`${inTotal}$`}</span>
                  </div>
                  {cancellationPolicy === 'non_refundable' && isLastTransitionAccept ? null : (
                    <div className={css.rowContainerButtonMobile}>
                      <button
                        className={css.buttonEditDelete}
                        onClick={() =>
                          onCancelClick(
                            transactionId,
                            isLastTransitionAccept,
                            cancellationPolicy,
                            date
                          )
                        }
                      >
                        <span className={css.buttonDeleteText}>
                          <FormattedMessage id="ListingItineraryTable.cancel" />
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <table className={css.table}>
            <tbody>
              <tr className={css.tableHeadingRow}>
                {tabledataHeader.map((item, index) => {
                  return (
                    <th
                      className={index === 0 ? css.itemContainerFirst : css.itemContainer}
                      key={index}
                    >
                      <span className={css.itemHeader}>{item}</span>
                    </th>
                  );
                })}
              </tr>
              {transactions.map((transaction, index) => {
                const price = (transaction.attributes.lineItems[0].unitPrice.amount / 100).toFixed(
                  2
                );
                const seats = transaction.attributes.lineItems[0].seats;
                const units = parseInt(transaction.attributes.lineItems[0].units.value);
                const inTotal = (transaction.attributes.payinTotal.amount / 100).toFixed(2);
                const date = new Date(transaction.attributes.protectedData.start);
                const time = new Date(
                  transaction.attributes.protectedData.start
                ).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                });
                const bookingDate = `${date.getMonth() +
                  1}.${date.getDate()}.${date.getFullYear()}`;
                const name = transaction.attributes.protectedData.listingName;
                const guest = seats;
                const transactionId = transaction.id;
                const isLastTransitionAccept =
                  transaction.attributes.lastTransition === ACCEPTED_NAME;
                const listingId = transaction.relationships.listing.data.id;
                const cancellationPolicy = transaction.attributes.protectedData.cancellationPolicy
                  ? transaction.attributes.protectedData.cancellationPolicy
                  : 'medium';

                const isActive = activelabelId ? activelabelId.uuid === listingId.uuid : false;

                return (
                  <tr
                    className={isActive ? css.tableDataRowActive : css.tableDataRow}
                    key={index}
                    onMouseEnter={() => {
                      onActivateTransaction(listingId);
                    }}
                    onMouseLeave={() => {
                      onActivateTransaction(null);
                    }}
                  >
                    <th className={css.itemContainerFirst}>
                      <span className={css.itemTable}>{index + 1}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{bookingDate}</span>
                    </th>
                    <th className={css.itemContainerThird}>
                      <span className={css.itemTable}>{time}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{name}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{`${price * units}$`}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{guest}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{`${inTotal}$`}</span>
                    </th>
                    <th className={css.itemContainerButton}>
                      {cancellationPolicy === 'non_refundable' && isLastTransitionAccept ? null : (
                        <button
                          className={css.buttonEditDelete}
                          onClick={() =>
                            onCancelClick(
                              transactionId,
                              isLastTransitionAccept,
                              cancellationPolicy,
                              date
                            )
                          }
                        >
                          <span className={css.buttonDeleteText}>
                            <FormattedMessage id="ListingItineraryTable.cancel" />
                          </span>
                        </button>
                      )}
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )
      ) : null}
    </div>
  );
};

ListingItineraryTable.propTypes = {
  editClick: func,
  deleteClick: func,
  confirmClick: func,
  transactions: array,
  disableButtonConfirm: bool,
};

export default ListingItineraryTable;
