import axios from 'axios';
import { updateProfile } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { fetchCurrentUser } from './user.duck';
import AuthHeader from '../helpers/AuthHeader';
import store from 'store';

const API_URL = process.env.REACT_APP_API_URL;

export const SMS_VERIFICATION_REQUEST_REQUEST = 'app/SmsVerification/SMS_VERIFICATION_REQUEST_REQUEST';
export const SMS_VERIFICATION_REQUEST_SUCCESS = 'app/SmsVerification/SMS_VERIFICATION_REQUEST_SUCCESS';
export const SMS_VERIFICATION_REQUEST_ERROR = 'app/SmsVerification/SMS_VERIFICATION_REQUEST_ERROR';

export const SMS_VERIFICATION_STATUS_REQUEST = 'app/SmsVerification/SMS_VERIFICATION_STATUS_REQUEST';
export const SMS_VERIFICATION_STATUS_SUCCESS = 'app/SmsVerification/SMS_VERIFICATION_STATUS_SUCCESS';
export const SMS_VERIFICATION_STATUS_RESET = 'app/SmsVerification/SMS_VERIFICATION_STATUS_RESET';
export const SMS_VERIFICATION_STATUS_ERROR = 'app/SmsVerification/SMS_VERIFICATION_STATUS_ERROR';

const initialState = {
  verificationRequestSuccess: null,
  verificationRequestError: null,

  phoneVerified: false,
  verificationStatusSuccess: false,
  verificationStatusError: false,
  smsRequestData: null
};

export default function reducer(state = initialState, action = {}){
  const { type, payload } = action;
  switch (type) {
    case SMS_VERIFICATION_REQUEST_REQUEST:
      return {
        ...state,
        verificationRequestSuccess: false,
        verificationRequestError: null,
        verificationStatusError: '',
        smsRequestData: {}
      };
    case SMS_VERIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        verificationRequestSuccess: true,
        verificationRequestError: null,
        smsRequestData: payload
      };
    case SMS_VERIFICATION_REQUEST_ERROR:
      return {
        ...state,
        verificationRequestSuccess: false,
        verificationRequestError: payload,
        smsRequestData: {}
      };
    case SMS_VERIFICATION_STATUS_REQUEST:
      return {
        ...state,
        verificationStatusSuccess: false,
      };
    case SMS_VERIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        phoneVerified: true,
        verificationStatusSuccess: true,
        verificationRequestError: null
      };
    case SMS_VERIFICATION_STATUS_RESET:
      return {
        ...state,
        phoneVerified: false,
      };
    case SMS_VERIFICATION_STATUS_ERROR:
      return {
        ...state,
        verificationRequestError: payload,
      };
    default:
      return state;
  }
}

export const smsVerificationRequestRequest = () => ({
  type: SMS_VERIFICATION_REQUEST_REQUEST
});
export const smsVerificationRequestSuccess = payload => ({
  type: SMS_VERIFICATION_REQUEST_SUCCESS, payload
});
export const smsVerificationRequestError = error => ({
  type: SMS_VERIFICATION_REQUEST_ERROR,
  payload: error
});

export const smsVerificationStatusRequest = () => ({
  type: SMS_VERIFICATION_STATUS_REQUEST
});
export const smsVerificationStatusSuccess = () => ({
  type: SMS_VERIFICATION_STATUS_SUCCESS
});
export const smsVerificationStatusReset = () => ({
  type: SMS_VERIFICATION_STATUS_RESET
});
export const smsVerificationStatusError = error => ({
  type: SMS_VERIFICATION_STATUS_ERROR,
  payload: error
});

export const smsVerificationRequest = userId => (dispatch, getState) => {
  dispatch(smsVerificationRequestRequest());
  const tokenParams = `?user_id=${userId}`;
  return axios.get(`${API_URL}/api/v1/user/phone_verify${tokenParams}`, { headers: {'Authorization': `Token ${store.get('user').token}`} })
    .then(response => {
      if(response.data.status <= 200) {
        dispatch(smsVerificationRequestSuccess(response.data.result));
      } else {
        dispatch(smsVerificationRequestError(response.data.message));
      }
    })
}

export const smsVerify = (userId, token) => (dispatch, getState) => {
  dispatch(smsVerificationStatusRequest());
  let protectedData = Object.assign({}, getState().user.currentUser.attributes.profile.protectedData);
  const tokenParams = `?token=${token}&user_id=${userId}`;
  return axios.get(`${API_URL}/api/v1/user/phone_confirm${tokenParams}`, { headers: {'Authorization': `Token ${store.get('user').token}`} })
    .then(response => {
      if(response.data.status <= 200) {
        if(!protectedData.phoneVerified){
          protectedData.phoneVerified = true;
        }
        dispatch(smsVerificationStatusSuccess());
        dispatch(updateProfile({
          protectedData: { ...protectedData }
        }));
        return protectedData.phoneVerified;
      } else {
        dispatch(smsVerificationStatusError(response.data.message));
      }
    })
}
