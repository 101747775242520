import React from 'react';
import { bool, func, shape, string, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';
import CancellationPolicySelectFieldMaybe from './CancellationPolicySelectFieldMaybe';
import { required } from '../../util/validators';
import Pdf from '../../containers/AboutCreatingAccountPage/TOC_About_Localfnf_1.1.pdf';

import css from './EditListingPoliciesForm.css';

export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        cancellationPolicy,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values
      } = formRenderProps;

      const rules = values.rules;

      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesLabel',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <p className={css.subtitle}>
            <FormattedMessage id="EditListingPoliciesPanel.subtitle" />
          </p>

          <FieldTextInput
            id="rules"
            name="rules"
            className={css.policy}
            type="textarea"
            label={rulesLabelMessage}
            placeholder={rulesPlaceholderMessage}
            rows={rules ? 1 : 10}
          />

          <h2>
            <FormattedMessage id="EditListingPoliciesForm.cancellationPolicyTitle" />
          </h2>

          <p>
            <span className={css.cancellationOptionTitle}>Flexible:</span>
            <FormattedMessage id="EditListingPoliciesForm.cancellationPolicyFlexible" />
          </p>
          <p>
            <span className={css.cancellationOptionTitle}>Medium:</span>
            <FormattedMessage id="EditListingPoliciesForm.cancellationPolicyMedium" />
          </p>
          <p>
            <span className={css.cancellationOptionTitle}>Strict:</span>
            <FormattedMessage id="EditListingPoliciesForm.cancellationPolicyStrict" />
          </p>
          <p>
            <span className={css.cancellationOptionTitle}>Non-refundable:</span>
            <FormattedMessage id="EditListingPoliciesForm.nonRefundablePolicy" />
          </p>

          <CancellationPolicySelectFieldMaybe
            className={css.cancellationPolicy}
            id="cancellationPolicy"
            name="cancellationPolicy"
            cancellationPolicy={cancellationPolicy}
            intl={intl}
          />

          <a className={css.note} href={Pdf} target="_blank">
            <FormattedMessage id="EditListingPoliciesForm.note" />
          </a>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  cancellationPolicy: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
