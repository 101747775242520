import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  Favorite,
  NamedLink,
  ShoppingCartHeader,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    cartList,
    transactions,
  } = props;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const shoppingCartDot = cartList && cartList.length > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const userType =
    currentUser !== null && currentUser.attributes.profile.publicData.userRole !== undefined
      ? currentUser.attributes.profile.publicData.userRole
      : null;

  const userApproved =
    currentUser !== null && currentUser.attributes.profile.protectedData.approved !== undefined
      ? currentUser.attributes.profile.protectedData.approved
      : null;

  const userId = currentUser && currentUser.id && currentUser.id.uuid;

  const createListingLink =
    // userType === 'Host' && userApproved === true ? (
    userApproved === true ? (
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    ) : null;

  const showListingLink = (
    // userType === 'Host' ? (
    <NamedLink
      className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
      name="ManageListingsPage"
    >
      <span className={css.menuItemBorder} />
      <FormattedMessage id="TopbarDesktop.yourListingsLink" />
    </NamedLink>
  );
  // ) : null;

  const showReviewsLink = userId && (
    <NamedLink
      className={classNames(css.yourListingsLink, currentPageClass('ReviewsPage'))}
      name="ReviewsPage"
      params={{ id: userId }}
    >
      <span className={css.menuItemBorder} />
      <FormattedMessage id="TopbarDesktop.yourReviewsLink" />
    </NamedLink>
  );

  const myCalendarLink = currentUserHasListings ? (
    <NamedLink
      className={classNames(css.yourListingsLink, currentPageClass('MyCalendarPage'))}
      name="MyCalendarPage"
    >
      <span className={css.menuItemBorder} />
      <FormattedMessage id="TopbarDesktop.myCalendarLink" />
    </NamedLink>
  ) : null;

  const myItineraryLink = transactions.length > 0 ? (
    <NamedLink
      className={classNames(css.yourListingsLink, currentPageClass('MyItineraryPage'))}
      name="MyItineraryPage"
    >
      <span className={css.menuItemBorder} />
      <FormattedMessage id="TopbarDesktop.myItineraryLink" />
    </NamedLink>
  ) : null;

  const profileMenu =
    authenticatedOnClientSide && userId ? (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent arrowPosition={false} className={css.profileMenuContent}>
          <MenuItem key="ManageListingsPage">
            <>{showListingLink}</>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="MyCalendarPage">{myCalendarLink}</MenuItem>
          <MenuItem key="MyItineraryPage">{myItineraryLink}</MenuItem>
          <MenuItem key="ReviewsPage">{showReviewsLink}</MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const favoritesLink = !isAuthenticatedOrJustHydrated ? null : (
    <NamedLink className={css.favoritesLink} name="FavoritesPage">
      <Favorite format="desktop" className={css.account} />
    </NamedLink>
  );

  const cartLink =
    !isAuthenticatedOrJustHydrated || !userApproved ? null : (
      <NamedLink className={css.shoppingCart} name="ShoppingCart">
        <ShoppingCartHeader
          format="desktop"
          className={css.account}
          shoppingCartDot={shoppingCartDot}
        />
      </NamedLink>
    );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}
      {createListingLink}
      {cartLink}
      {inboxLink}
      {favoritesLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
