import React, { useState, useEffect } from 'react';
import { func, shape, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import moment from 'moment';

import { LINE_ITEM_UNITS, propTypes } from '../../util/types';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  confirmPayment,
  sendMessage,
  clearTransaction,
  setListingStatus,
} from '../CheckoutPage/CheckoutPage.duck';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
} from '../../util/data';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { handleCardPayment, retrievePaymentIntent, handleCardSetup } from '../../ducks/stripe.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { txIsPaymentPending, txIsPaymentExpired } from '../../util/transaction';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { personalInfoRestricted } from '../../util/validators';
import { StripePaymentCartForm } from '../../forms';
import { parse } from '../../util/urlHelpers';
import {
  Page,
  NamedLink,
  LayoutWrapperFooter,
  Footer,
  ShoppingCartTable,
  SearchMap,
  ModalInMobile,
} from '../../components';
import { TopbarContainer } from '..';

import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  removeListingFromCart,
  editListingInCart,
  setTransactionCartInProgress,
  setActiveListing,
  setActiveLabel,
} from '../ShoppingCartPage/ShoppingCart.duck';
import { queryOwnTransactions } from '../MyItineraryPage/MyItineraryPage.duck';
import { createStripeSetupIntent } from '../PaymentMethodsPage/PaymentMethodsPage.duck';
import { storeData, clearData } from '../CheckoutPage/CheckoutPageSessionHelpers';
import { minutesBetween } from '../../util/dates';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { searchListings } from '../SearchPage/SearchPage.duck';

import css from './ShoppingCart.css';

const STORAGE_KEY = 'CheckoutPage';
const HOUR = 3600000;
const THREE_DAYS = 259200000;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const { LatLng, LatLngBounds } = sdkTypes;

const { Money } = sdkTypes;

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const formatExpiredTime = time => {
  const formatTime = moment(time).format('hh:mm A');
  const formatDate = moment(time).format('MMM DD, YYYY');

  return `${formatTime} on ${formatDate}`;
};

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

const filterShoppingCart = shoppingCartList => {
  const currentDate = new Date();
  return shoppingCartList.filter(listing => new Date(listing.bookingDatesStart) > currentDate);
};

function ShoppingCartComponent(props) {
  const {
    onRemoveListingFromCart,
    onEditListingInCart,
    cartList,
    history,
    speculateTransactionError,
    initiateOrderError,
    fetchStripeCustomer,
    retrievePaymentIntentError,
    currentUser,
    speculatedTransaction,
    paymentIntent,
    dispatch,
    stripeCustomerFetched,
    onInitiateOrder,
    onHandleCardPayment,
    onConfirmPayment,
    onSendMessage,
    onSavePaymentMethod,
    handleCardPaymentError,
    confirmPaymentError,
    intl,
    transactionCartInProgress,
    onSetTransactionCartInProgress,
    idInProgress,
    onClearTransaction,
    onCreateSetupIntent,
    onHandleCardSetup,
    onUpdateProfile,
    onManageDisableScrolling,
    activeListingId,
    location,
    mapListings,
    onActivateListing,
    onActivateLabel,
    activelabelId,
    isClosedListing,
    onSetListingStatus,
    scrollingDisabled,
    onQueryOwnTransactions,
  } = props;

  const [userCartList, setUserCartList] = useState(cartList);
  const [pageData, setPageData] = useState({});
  const [saveCardProgress, setSaveCardProgress] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [transaction, setTrancasction] = useState(null);
  const [disabledConfirmButton, setDisabledConfirmButton] = useState(true);
  const [clickSubmit, setClickSubmit] = useState(false);
  const [isChangePaymentCard, setIsChangePaymentCard] = useState(false);
  const [isSearchMapOpenOnMobile, setIsSearchMapOpenOnMobile] = useState(props.tab === 'map');
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [listingConfirm, setListingConfirm] = useState();

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  const onMapMoveEnd = (viewportBoundsChanged, data) => {};

  const getClientSecret = setupIntent => {
    return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
  };
  const getPaymentParams = (currentUser, formValues) => {
    const { name, addressLine1, addressLine2, postal, state, city, country } = formValues;
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const paymentParams = {
      payment_method_data: {
        billing_details: billingDetails,
      },
    };

    return paymentParams;
  };

  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(
    '?bounds=81.77060342%2C96.19903564%2C-81.46941277%2C-167.91778564&mapSearch=true',
    {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    }
  );

  const { origin } = searchInURL || {};

  const bounds = new LatLngBounds(
    new LatLng(37.517576, -68.70083),
    new LatLng(30.477399, -134.25908)
  );

  const fullName =
    currentUser !== null
      ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
      : null;

  const hasUserPaymentDefaultMethod =
    currentUser !== null &&
    currentUser.stripeCustomer !== undefined &&
    currentUser.stripeCustomer !== null
      ? currentUser.stripeCustomer.defaultPaymentMethod
        ? {
            card: undefined,
            formId: 'CheckoutPagePaymentForm',
            formValues: {
              name: fullName,
            },
            message: null,
            paymentMethod: 'defaultCard',
          }
        : {}
      : null;

  const [paymentData, setPaymentData] = useState(hasUserPaymentDefaultMethod);

  const userType =
    currentUser !== null && currentUser.attributes.profile.publicData.userRole !== undefined
      ? currentUser.attributes.profile.publicData.userRole
      : null;

  const userApproved =
    currentUser !== null && currentUser.attributes.profile.protectedData.approved !== undefined
      ? currentUser.attributes.profile.protectedData.approved
      : null;

  // if ((userType === 'Host' && userType !== null) || (!userApproved && userApproved !== null)) {
  //   history.push('/');
  // }

  if (!userApproved && userApproved !== null) {
    history.push('/');
  }

  const redirectToClosedListing = () => {
    deleteClick(listingConfirm.idForCart);
    history.push(createListingURL(routeConfiguration(), listingConfirm));
  };

  useEffect(() => {
    if (window) {
      onClearTransaction();
      loadInitialData();
    }
  }, []);

  useEffect(() => {
    setPaymentData({
      card: undefined,
      formId: 'CheckoutPagePaymentForm',
      formValues: {
        name: fullName,
      },
      message: null,
      paymentMethod: 'defaultCard',
    });
  }, [fullName]);

  useEffect(() => {
    setUserCartList(filterShoppingCart(cartList));
  }, [cartList]);

  useEffect(() => {
    if (speculatedTransaction !== null && clickSubmit) {
      handleSubmit(speculatedTransaction);
    }
  }, [speculatedTransaction]);

  useEffect(() => {
    if (hasUserPaymentDefaultMethod) {
      if (currentUser.stripeCustomer.defaultPaymentMethod) {
        setDisabledConfirmButton(false);
      }
    }
  }, [hasUserPaymentDefaultMethod]);

  useEffect(() => {
    setTrancasction(props.transaction);
  }, [props.transaction]);

  useEffect(() => {
    isClosedListing && redirectToClosedListing();
    onSetListingStatus(false);
  }, [isClosedListing]);

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  function loadInitialData() {
    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();
  }

  async function handlePaymentIntent(handlePaymentParams) {
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents ? Promise.resolve(storedTx) : onInitiateOrder(fnParams, storedTx.id);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        setPageData({ ...pageData });
        setTrancasction(order);
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent })
        : onHandleCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;
      return onConfirmPayment(fnParams);
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSendMessage,
      fnSavePaymentMethod
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const lineItems = [];

    const createdAt = new Date(tx.attributes.createdAt).getTime();
    const startBooking = new Date(tx.booking.attributes.start).getTime();

    let expiredTime = moment(startBooking).subtract({hours:1});
    if (createdAt + THREE_DAYS < startBooking) expiredTime = moment(createdAt).add(3, 'day');

    const formatDate = formatExpiredTime(expiredTime);

    const orderParams = {
      listingId: pageData.listing.id,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      seats: tx.attributes.lineItems[0].seats,
      lineItems: [
        ...lineItems,
        {
          code: tx.attributes.lineItems[0].code,
          seats: tx.attributes.lineItems[0].seats,
          units: tx.attributes.lineItems[0].units,
          unitPrice: new Money(tx.attributes.lineItems[0].unitPrice.amount, config.currency),
        },
      ],
      protectedData: {
        listingName: pageData.listing.attributes.title,
        start: tx.booking.attributes.start.toString(),
        cancellationPolicy: pageData.listing.attributes.publicData.cancellationPolicy,
        expiredTime: formatDate,
      },
      ...optionalPaymentParams,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  const { bookingDates } = pageData;
  const transactionData = pageData.transaction;
  const listingData = pageData.listing;

  const existingTransaction = ensureTransaction(transactionData);

  // Since the listing data is already given from the ListingPage
  // and stored to handle refreshes, it might not have the possible
  // deleted or closed information in it. If the transaction
  // initiate or the speculative initiate fail due to the listing
  // being deleted or closec, we should dig the information from the
  // errors and not the listing data.
  const listingNotFound =
    isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
    isTransactionInitiateListingNotFoundError(initiateOrderError);

  const currentListing = ensureListing(listingData);
  const listingTitle = currentListing.attributes.title;

  const createListingURL = (routes, listing) => {
    const id = listing.id;
    const slug = createSlug(listing.listingName);

    const linkProps = {
      name: 'ListingPage',
      params: { id, slug },
    };

    return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
  };

  const listingLink = currentListing.id && (
    <NamedLink
      name="ListingPage"
      params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
    >
      <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
    </NamedLink>
  );

  const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
  const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
  const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
    initiateOrderError
  );
  const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

  let initiateOrderErrorMessage = null;
  let listingNotFoundErrorMessage = null;

  const currentAuthor = ensureUser(currentListing.author);

  const isPaymentExpired = checkIsPaymentExpired(existingTransaction);

  if (listingNotFound) {
    listingNotFoundErrorMessage = (
      <p className={css.notFoundError}>
        <FormattedMessage id="CheckoutPage.listingNotFoundError" />
      </p>
    );
  } else if (isAmountTooLowError) {
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
      </p>
    );
  } else if (isBookingTimeNotAvailableError) {
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
      </p>
    );
  } else if (isChargeDisabledError) {
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
      </p>
    );
  } else if (stripeErrors && stripeErrors.length > 0) {
    // NOTE: Error messages from Stripes are not part of translations.
    // By default they are in English.
    const stripeErrorsAsString = stripeErrors.join(', ');
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage
          id="CheckoutPage.initiateOrderStripeError"
          values={{ stripeErrors: stripeErrorsAsString }}
        />
      </p>
    );
  } else if (initiateOrderError) {
    // Generic initiate order error
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
      </p>
    );
  }

  let speculateErrorMessage = null;

  if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
    speculateErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
      </p>
    );
  } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
    speculateErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
      </p>
    );
  } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
    speculateErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
      </p>
    );
  } else if (speculateTransactionError) {
    speculateErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
      </p>
    );
  }

  function createTransaction(data) {
    setClickSubmit(true);
    setListingConfirm(data);
    const { fetchSpeculatedTransaction } = props;

    const idForCart = data.idForCart;

    onSetTransactionCartInProgress(true, idForCart);

    const bookingData = {
      bookingEndDate: {
        date: new Date(data.bookingEndData),
      },
      bookingStartDate: {
        date: new Date(data.bookingStartData),
      },
      guestSelect: data.guests,
      seats: data.seats,
      units: data.units,
    };
    const bookingDates = {
      bookingEnd: new Date(data.bookingDatesEnd),
      bookingStart: new Date(data.bookingDatesStart),
    };
    const listing = {
      attributes: {
        title: data.listingName,
        publicData: {
          cancellationPolicy: data.cancellationPolicy,
        },
      },
      author: {
        id: {
          uuid: data.authorIdUuid,
          _sdkType: data.authorIdSdkType,
        },
        type: 'user',
        attributes: {
          profile: {
            displayName: data.authorDisplayName,
          },
        },
        profileImage: {},
      },
      id: {
        uuid: data.id,
        _sdkType: 'UUID',
      },
    };

    const pageData = { bookingData, bookingDates, listing, transaction, idForCart };

    const listingId = pageData.listing.id;
    const { bookingStart, bookingEnd } = pageData.bookingDates;
    const seats = data.seats;
    const units = data.units;
    const price = data.price;

    const lineItems = [];

    // Fetch speculated transaction for showing price in booking breakdown
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    fetchSpeculatedTransaction({
      listingId,
      bookingStart,
      bookingEnd,
      lineItems: [
        ...lineItems,
        {
          code: LINE_ITEM_UNITS,
          seats,
          units,
          unitPrice: new Money(price, config.currency),
        },
      ],
    });

    setPageData(pageData || {});
  }

  function changePaymantCard(value) {
    setIsChangePaymentCard(value);
  }

  function changePayment(data) {
    setDisabledConfirmButton(true);
    setPaymentData(data);

    setSaveCardProgress(true);

    const routes = routeConfiguration();

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;

    onCreateSetupIntent()
      .then(setupIntent => {
        const stripeParams = {
          stripe,
          card: data.card,
          setupIntentClientSecret: getClientSecret(setupIntent),
          paymentParams: getPaymentParams(currentUser, data.formValues),
        };

        return onHandleCardSetup(stripeParams);
      })
      .then(result => {
        const newPaymentMethod = result.setupIntent.payment_method;
        // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.
        console.log(result);

        return onSavePaymentMethod(stripeCustomer, newPaymentMethod);
      })
      .then(() => {
        setSaveCardProgress(false);
        setDisabledConfirmButton(false);
        setIsChangePaymentCard(false);
        // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
        fetchStripeCustomer();
        onUpdateProfile({
          publicData: {
            verifiedUserPayment: true,
          },
        });
        window.location.reload(false);
      })
      .catch(error => {
        console.error(error);
      });
  }

  function handleSubmit(data) {
    if (submitting) {
      return;
    }
    setSubmitting(true);

    const { card, message, paymentMethod, formValues } = paymentData;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    const validateMessage = personalInfoRestricted(message);

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: pageData,
      speculatedTransaction: data,
      stripe: stripe,
      card,
      billingDetails,
      validateMessage,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
    };

    handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { orderId, messageSuccess, paymentMethodSaved } = res;
        setSubmitting(false);

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, { id: orderId.uuid });
        const initialValues = {
          initialMessageFailedToTransaction,
          savePaymentMethodFailed: !paymentMethodSaved,
        };

        initializeOrderPage(initialValues, routes, dispatch);
        clearData(STORAGE_KEY);
        onSetTransactionCartInProgress(false, '');
        onRemoveListingFromCart(pageData.idForCart);
        onQueryOwnTransactions(currentUser.id, 1);
        history.push(orderDetailsPath);
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);
        onSetTransactionCartInProgress(false, '');
      });
  }

  function onStripeInitialized(stripeInit) {
    setStripe(stripeInit);

    const { paymentIntent, onRetrievePaymentIntent } = props;
    const tx = pageData ? pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }

  const hasDefaultPaymentMethod = !!(
    stripeCustomerFetched &&
    currentUser &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
  );

  const editClick = listing => {
    history.push(createListingURL(routeConfiguration(), listing));
    onEditListingInCart(listing.idForCart);
  };

  function deleteClick(id) {
    onRemoveListingFromCart(id);
  }

  // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
  const userName =
    currentUser && currentUser.attributes
      ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
      : null;

  // If paymentIntent status is not waiting user action,
  // handleCardPayment has been called previously.
  const hasPaymentIntentUserActionsDone =
    paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

  // If your marketplace works mostly in one country you can use initial values to select country automatically
  // e.g. {country: 'FI'}

  const initalValuesForStripePayment = { name: userName };

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
  const shouldShowSearchMap = !isMobileLayout || (isMobileLayout && isSearchMapOpenOnMobile);

  // Set topbar class based on if a modal is open in
  // a child component
  const topbarClasses = isMobileModalOpen
    ? classNames(css.topbarBehindModal, css.topbar)
    : css.topbar;

  const onMapIconClick = () => {
    setIsSearchMapOpenOnMobile(true);
  };

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ShoppingCartPage.schemaTitle' }, { siteTitle });

  const emptyCart = () => {
    return (
      <div className={css.headingContainer}>
        <h3 className={css.headingTitle}>{`Payment & Itinerary`}</h3>
        <p className={css.headingText}>
          <FormattedMessage id="ShopingCartPage.emtyCart" />
        </p>
      </div>
    );
  };

  const fullCart = () => {
    return (
      <>
        <div className={css.headingContainer}>
          <h3 className={css.headingTitle}>{`Payment & Itinerary`}</h3>
          <p className={css.headingText}>
            <FormattedMessage id="ShopingCartPage.fullCart" />
          </p>
        </div>
        <div className={css.paymentMethodsContainer}>
          {/* {initiateOrderErrorMessage} */}
          {listingNotFoundErrorMessage}
          {retrievePaymentIntentError ? (
            <p className={css.orderError}>
              <FormattedMessage
                id="CheckoutPage.retrievingStripePaymentIntentFailed"
                values={{ listingLink }}
              />
            </p>
          ) : null}
          <StripePaymentCartForm
            className={css.paymentForm}
            onSubmit={changePayment}
            inProgress={saveCardProgress}
            formId="CheckoutPagePaymentForm"
            paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
            authorDisplayName={currentAuthor.attributes.profile.displayName}
            showInitialMessageInput={false}
            initialValues={initalValuesForStripePayment}
            // initiateOrderError={initiateOrderError}
            handleCardPaymentError={handleCardPaymentError}
            confirmPaymentError={confirmPaymentError}
            hasHandledCardPayment={hasPaymentIntentUserActionsDone}
            loadingData={!stripeCustomerFetched}
            defaultPaymentMethod={
              hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
            }
            paymentIntent={paymentIntent}
            onStripeInitialized={onStripeInitialized}
            paymentData={paymentData}
            changePaymantCard={changePaymantCard}
          />
          {isPaymentExpired ? (
            <p className={css.orderError}>
              <FormattedMessage id="CheckoutPage.paymentExpiredMessage" values={{ listingLink }} />
            </p>
          ) : null}
        </div>
        <div className={css.tableContainer}>
          <ShoppingCartTable
            editClick={editClick}
            deleteClick={deleteClick}
            confirmClick={createTransaction}
            userCartList={userCartList}
            disableButtonConfirm={disabledConfirmButton || isChangePaymentCard}
            submitInProgress={transactionCartInProgress}
            idInProgress={idInProgress}
            onActivateListing={onActivateListing}
            activelabelId={activelabelId}
            speculateErrorMessage={speculateErrorMessage}
            initiateOrderErrorMessage={initiateOrderErrorMessage}
          />
        </div>
      </>
    );
  };

  return (
    <Page
      title={schemaTitle}
      contentType="website"
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: '',
        name: schemaTitle,
      }}
    >
      <TopbarContainer currentPage="ShoppingCart" className={topbarClasses} />
      <div className={css.container}>
        <div className={css.buttons}>
          <div className={css.mapIcon} onClick={onMapIconClick}>
            <FormattedMessage id="SearchFilters.openMapView" className={css.mapIconText} />
          </div>
        </div>
        <div className={css.paymentContainer}>
          {userCartList !== undefined
            ? userCartList.length > 0
              ? fullCart()
              : emptyCart()
            : emptyCart()}
        </div>
        <ModalInMobile
          className={css.mapPanel}
          id="SearchPage.map"
          isModalOpenOnMobile={isSearchMapOpenOnMobile}
          onClose={() => setIsSearchMapOpenOnMobile(false)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.mapWrapper}>
            {shouldShowSearchMap ? (
              <SearchMap
                reusableContainerClassName={css.map}
                activeListingId={activeListingId}
                bounds={bounds}
                center={origin}
                isSearchMapOpenOnMobile={isSearchMapOpenOnMobile}
                location={location}
                listings={mapListings || []}
                onMapMoveEnd={onMapMoveEnd}
                onCloseAsModal={() => {
                  onManageDisableScrolling('ShoppingCart.map', false);
                }}
                messages={intl.messages}
                onActivateLabel={onActivateLabel}
                typePage={'shoppingPage'}
                userCartList={userCartList}
              />
            ) : null}
          </div>
        </ModalInMobile>
      </div>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </Page>
  );
}

ShoppingCartComponent.propTypes = {
  onUpdateProfile: func.isRequired,
  speculateTransactionError: propTypes.error,
  onActivateListing: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const cartList =
    state.user.currentUser !== null
      ? state.user.currentUser.attributes.profile.publicData.cartList
      : [];
  const {
    listing,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    isClosedListing,
  } = state.CheckoutPage;
  const { currentUser } = state.user;
  const {
    transactionCartInProgress,
    idInProgress,
    activeListingId,
    activelabelId,
  } = state.ShoppingCartPage;
  const { handleCardPaymentError, retrievePaymentIntentError } = state.stripe;
  const { searchMapListingIds } = state.SearchPage;

  const resultIds = cartList =>
    cartList &&
    cartList.map(l => {
      return { uuid: `${l.id}`, _sdkType: 'UUID' };
    });

  const mapListings = getListingsById(
    state,
    unionWith(resultIds(cartList), searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    scrollingDisabled: isScrollingDisabled(state),
    cartList,
    speculateTransactionError,
    initiateOrderError,
    retrievePaymentIntentError,
    handleCardPaymentError,
    confirmPaymentError,
    transaction,
    speculatedTransaction,
    speculateTransactionInProgress,
    stripeCustomerFetched,
    bookingDates,
    bookingData,
    listing,
    currentUser,
    transactionCartInProgress,
    idInProgress,
    activeListingId,
    mapListings,
    activelabelId,
    isClosedListing,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: params => dispatch(speculateTransaction(params)),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onRemoveListingFromCart: data => dispatch(removeListingFromCart(data)),
  onEditListingInCart: data => dispatch(editListingInCart(data)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onSetTransactionCartInProgress: (inProgress, id) =>
    dispatch(setTransactionCartInProgress(inProgress, id)),
  onClearTransaction: () => dispatch(clearTransaction()),
  onCreateSetupIntent: params => dispatch(createStripeSetupIntent(params)),
  onHandleCardSetup: params => dispatch(handleCardSetup(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onActivateLabel: listingId => dispatch(setActiveLabel(listingId)),
  onSetListingStatus: value => dispatch(setListingStatus(value)),
  onQueryOwnTransactions: (userId, page) => dispatch(queryOwnTransactions(userId, page)),
});

const ShoppingCart = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ShoppingCartComponent);

ShoppingCart.setInitialValues = initialValues => setInitialValues(initialValues);

ShoppingCart.displayName = 'ShoppingCart';

ShoppingCart.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: 50,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default ShoppingCart;
