/*
 * Marketplace specific configuration.
 */

export const categories = [
  { key: 'food', label: 'Food' },
  { key: 'friend', label: 'Friend' },
  { key: 'online', label: 'Online activity' },
];

export const cancellationPolicy = [
  { key: 'medium', label: 'Medium' },
  { key: 'flexible', label: 'Flexible' },
  { key: 'strict', label: 'Strict' },
  { key: 'non_refundable', label: 'Non-refundable' },
];

export const duration = [
  { key: '1 hour', label: '1 hour' },
  { key: '2 hours', label: '2 hours' },
  { key: '3 hours', label: '3 hours' },
  { key: '4 hours', label: '4 hours' },
  { key: '5 hours', label: '5 hours' },
  { key: '6 hours', label: '6 hours' },
  { key: '7 hours', label: '7 hours' },
  { key: '8 hours', label: '8 hours' },
  { key: '9 hours', label: '9 hours' },
  { key: '10 hours', label: '10 hours' },
  { key: '11 hours', label: '11 hours' },
  { key: '12 hours', label: '12 hours' },
  { key: '13 hours', label: '13 hours' },
  { key: '14 hours', label: '14 hours' },
  { key: '15 hours', label: '15 hours' },
  { key: '16 hours', label: '16 hours' },
  { key: '17 hours', label: '17 hours' },
  { key: '18 hours', label: '18 hours' },
  { key: '19 hours', label: '19 hours' },
  { key: '20 hours', label: '20 hours' },
  { key: '21 hours', label: '21 hours' },
  { key: '22 hours', label: '22 hours' },
  { key: '23 hours', label: '23 hours' },
  { key: '24 hours', label: '24 hours' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeLengthFilterConfig = {
  active: true,

  // A global time zone to use in availability searches. As listings
  // can be in various time zones, we must decide what time zone we
  // use in search when looking for available listings within a
  // certain time interval.
  //
  // If you have all/most listings in a certain time zone, change this
  // config value to that.
  //
  // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  searchTimeZone: 'Etc/UTC',

  // Options for the minimum duration of the booking
  options: [
    { key: '0', label: 'Any length' },
    { key: '60', label: '1 hour', shortLabel: '1h' },
    { key: '120', label: '2 hours', shortLabel: '2h' },
  ],
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};
