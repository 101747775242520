import axios from 'axios';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const { UUID } = sdkTypes;

const API_URL = process.env.REACT_APP_API_NODE_URL ? process.env.REACT_APP_API_NODE_URL : '';

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// ================ Action types ================ //

export const FETCH_TRANSACTIONS_REQUEST = 'app/MyitineraryPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/MyitineraryPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/MyitineraryPage/FETCH_TRANSACTIONS_ERROR';

export const FETCH_TRANSACTION_REQUEST = 'app/MyitineraryPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/MyitineraryPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/MyitineraryPage/FETCH_TRANSACTION_ERROR';
export const FETCH_TRANSACTION_RESET = 'app/MyitineraryPage/FETCH_TRANSACTION_RESET';

// ================ Reducer ================ //

const initialState = {
  transactions: [],
  queryTransactionsProgress: false,
  queryTransactionsError: null,
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  fetchTransactionSuccess: false,
};

const manageMyItineraryPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        queryTransactionsProgress: true,
        queryTransactionsError: null,
        transactions: [],
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: payload.data,
        queryTransactionsProgress: false,
        queryTransactionsError: null,
      };
    case FETCH_TRANSACTIONS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryTransactionsProgress: false, queryTransactionsError: payload };

    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchTransactionInProgress: true,
        fetchTransactionError: null,
        fetchTransactionSuccess: false,
      };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return {
        ...state,
        fetchTransactionInProgress: false,
        transactionRef,
        fetchTransactionSuccess: true,
      };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchTransactionInProgress: false,
        fetchTransactionError: payload,
        fetchTransactionSuccess: false,
      };
    case FETCH_TRANSACTION_RESET:
      return {
        ...state,
        fetchTransactionInProgress: false,
        fetchTransactionError: null,
        fetchTransactionSuccess: false,
      };

    default:
      return state;
  }
};

export default manageMyItineraryPageReducer;

// ================ Selectors ================ //

// ================ Action creators ================ //

export const queryTransactionsRequest = () => ({
  type: FETCH_TRANSACTIONS_REQUEST,
});

export const queryTransactionsSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: { data: response },
});

export const queryTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });
export const fetchTransactionReset = () => ({ type: FETCH_TRANSACTION_RESET });

// Throwing error for new (loadData may need that info)
export const queryOwnTransactions = (userId, pageParams) => async (dispatch, getState, sdk) => {
  const allTransactions = [];
  const currentUserId = userId.uuid;

  dispatch(queryTransactionsRequest());
  const result = await dispatch(getAllTransactions(currentUserId, pageParams));

  const transactions = result && result.response.data.data.data;
  const totalPages = result ? result.pages : 0;
  transactions && allTransactions.push(...transactions);

  for (let i = 2; i <= totalPages; i++) {
    const requestResult = await dispatch(getAllTransactions(currentUserId, i));
    const transactions = requestResult && requestResult.response.data.data.data;
    requestResult && allTransactions.push(...transactions);
  }

  const currenDate = Date.now();

  const filterTransaction = transactions
    ? transactions.filter(
        transaction =>
          (transaction.attributes.lastTransition === TRANSITION_ACCEPT &&
            transaction.attributes.protectedData.start &&
            currenDate < new Date(transaction.attributes.protectedData.start).getTime()) ||
          (transaction.attributes.lastTransition === TRANSITION_CONFIRM_PAYMENT &&
            transaction.attributes.protectedData.start &&
            currenDate < new Date(transaction.attributes.protectedData.start).getTime())
      )
    : [];

  const sortTransactions = filterTransaction
    ? filterTransaction.sort((a, b) => {
        if (filterTransaction && filterTransaction.length > 0) {
          return +new Date(a.attributes.protectedData.start) >
            +new Date(b.attributes.protectedData.start)
            ? 1
            : +new Date(b.attributes.protectedData.start) >
              +new Date(a.attributes.protectedData.start)
            ? -1
            : 0;
        }
      })
    : [];

  dispatch(queryTransactionsSuccess(sortTransactions));
};

export const getAllTransactions = (currentUserId, page) => dispatch => {
  const userType = 'customer';

  // const url = `/api/user-transactions?userId=${currentUserId}&page=${page}&userType=${userType}`;
  const url = `${API_URL}/api/user-transactions?userId=${currentUserId}&page=${page}&userType=${userType}`; // Choose for localhost

  return axios
    .get(url)
    .then(response => {
      const totalPages = response.data.data.meta.totalPages;

      return { response: response, pages: totalPages };
    })
    .catch(e => {
      dispatch(queryTransactionsError(storableError(e)));
    });
};

export const fetchTransaction = txId => (dispatch, getState, sdk) => {
  const id = new UUID(txId.uuid);
  dispatch(fetchTransactionRequest());

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(async response => {
      await dispatch(addMarketplaceEntities(response));
      await dispatch(fetchTransactionSuccess(response));
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};
