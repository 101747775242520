import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { ensureUser, ensureCurrentUser } from '../../util/data';

import css from './ListingPage.css';

const AuthorProfileLink = props => {
  const { user } = props;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const authorName = user.attributes.profile.displayName

  return (
    <span>
      <FormattedMessage id="ListingPage.hostedBy" />
      
      <NamedLink className={css.authorName} name="ProfilePage" params={{ id: ensuredUser.id.uuid }}>
        <FormattedMessage id="ListingPage.hostedByAuthorName" values={{ name: authorName }} />
      </NamedLink>
    </span>
  );
};

export default AuthorProfileLink;
