import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

// ================ Action types ================ //

export const ADD_LISTING_TO_CART = 'app/ShoppingCart/ADD_LISTING_TO_CART';
export const REMOVE_LISTING_FROM_CART = 'app/ShoppingCart/REMOVE_LISTING_FROM_CART';
export const EDIT_LISTING_IN_CART = 'app/ShoppingCart/EDIT_LISTING_IN_CART';
export const TRANSACTION_CART_IN_PROGRESS = 'app/ShoppingCart/TRANSACTION_CART_IN_PROGRESS';
export const TRANSACTION_CART_IN_PROGRESS_SET_FALSE =
  'app/ShoppingCart/TRANSACTION_CART_IN_PROGRESS_SET_FALSE';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/ShoppingCart/SEARCH_MAP_SET_ACTIVE_LISTING';
export const SEARCH_MAP_SET_ACTIVE_LABEL = 'app/ShoppingCart/SEARCH_MAP_SET_ACTIVE_LABEL';

// ================ Reducer ================ //

const initialState = {
  cartList: [],
  transactionCartInProgress: false,
  idInProgress: '',
  activeListingId: [],
};

const ShoppingCartReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_LISTING_TO_CART:
      return { ...state, cartList: payload };

    case REMOVE_LISTING_FROM_CART:
      return { ...state, cartList: payload };

    case EDIT_LISTING_IN_CART:
      return { ...state };
    case TRANSACTION_CART_IN_PROGRESS:
      return { ...state, transactionCartInProgress: payload.inProgress, idInProgress: payload.id };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case SEARCH_MAP_SET_ACTIVE_LABEL:
      return {
        ...state,
        activelabelId: payload,
      };

    case TRANSACTION_CART_IN_PROGRESS_SET_FALSE:
      return { ...state, transactionCartInProgress: false };

    default:
      return state;
  }
};

export default ShoppingCartReducer;

// ================ Action creators ================ //

const filterShoppingCart = shoppingCartList => {
  const currentDate = new Date();
  return shoppingCartList.filter(listing => new Date(listing.bookingDatesStart) > currentDate);
};

export const addListingToCart = initialValues => (dispatch, getState) => {
  const id = initialValues.listing.id.uuid;
  const idForCart = Date.now();
  const bookingDatesStart = initialValues.bookingDates.bookingStart.toString();
  const bookingDatesEnd = initialValues.bookingDates.bookingEnd.toString();
  const bookingStartData = initialValues.bookingData.bookingStartDate.date.toString();
  const bookingEndData = initialValues.bookingData.bookingEndDate.date.toString();
  const listingName = initialValues.listing.attributes.title;
  const price = initialValues.listing.attributes.price.amount;
  const authorIdUuid = initialValues.listing.author.id.uuid;
  const authorIdSdkType = initialValues.listing.author.id._sdkType;
  const authorDisplayName = initialValues.listing.author.attributes.profile.displayName;
  const seats = parseInt(initialValues.bookingData.guestSelect) || 1;
  const units = parseInt(initialValues.bookingData.quantity);
  const cancellationPolicy = initialValues.listing.attributes.publicData.cancellationPolicy;

  const { currentUser } = getState().user;

  const cartList =
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.cartList !== undefined
      ? currentUser.attributes.profile.publicData.cartList
      : [];

  const userCartList = cartList && cartList.length > 0 ? filterShoppingCart(cartList) : [];

  userCartList.push({
    id,
    idForCart,
    bookingDatesStart,
    bookingDatesEnd,
    bookingStartData,
    bookingEndData,
    listingName,
    price,
    authorIdUuid,
    authorIdSdkType,
    authorDisplayName,
    seats,
    units,
    cancellationPolicy,
  });
  dispatch({
    type: ADD_LISTING_TO_CART,
    payload: userCartList,
  });
  dispatch(
    updateProfile({
      publicData: {
        cartList: userCartList,
      },
    })
  );
};

export const removeListingFromCart = id => (dispatch, getState) => {
  const userCartList = getState().user.currentUser.attributes.profile.publicData.cartList;
  const resultCartList = userCartList.filter(listing => listing.idForCart !== id);

  const cartList =
    resultCartList && resultCartList.length > 0 ? filterShoppingCart(resultCartList) : [];

  dispatch({
    type: REMOVE_LISTING_FROM_CART,
    payload: cartList,
  });
  dispatch(
    updateProfile({
      publicData: {
        cartList: cartList,
      },
    })
  );
};

export const editListingInCart = id => dispatch => {
  dispatch({
    type: EDIT_LISTING_IN_CART,
  });
  dispatch(removeListingFromCart(id));
};

export const setTransactionCartInProgress = (inProgress, id) => dispatch => {
  dispatch({
    type: TRANSACTION_CART_IN_PROGRESS,
    payload: { inProgress, id },
  });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const setActiveLabel = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LABEL,
  payload: listingId,
});

export const setTransactionProgressFalse = () => ({
  type: TRANSACTION_CART_IN_PROGRESS_SET_FALSE,
});
