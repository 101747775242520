import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.css';

const UserNavComponent = props => {
  const {
    className,
    rootClassName,
    selectedPageName,
    currentUser,
    currentUserHasListings,
    transactions,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const userType =
    currentUser !== null && currentUser.attributes.profile.publicData.userRole !== undefined
      ? currentUser.attributes.profile.publicData.userRole
      : null;

  const userId = currentUser && currentUser.id && currentUser.id.uuid;

  const tabsForHost = [
    {
      text: <FormattedMessage id="ManageListingsPage.yourListings" />,
      selected: selectedPageName === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.myCalendar" />,
      selected: selectedPageName === 'MyCalendarPage',
      linkProps: {
        name: 'MyCalendarPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.myItinerary" />,
      selected: selectedPageName === 'MyItineraryPage',
      linkProps: {
        name: 'MyItineraryPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.myReviews" />,
      selected: selectedPageName === 'ReviewsPage',
      linkProps: {
        name: 'ReviewsPage',
        params: { id: userId },
      },
    },
  ];

  const tabsForGuest = [
    {
      text: <FormattedMessage id="ManageListingsPage.yourListings" />,
      selected: selectedPageName === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.myItinerary" />,
      selected: selectedPageName === 'MyItineraryPage',
      linkProps: {
        name: 'MyItineraryPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.myReviews" />,
      selected: selectedPageName === 'ReviewsPage',
      linkProps: {
        name: 'ReviewsPage',
        params: { id: userId },
      },
    },
  ];

  const tabs = currentUserHasListings ? tabsForHost : tabsForGuest;
  const filterTabs =
    transactions.length > 0 ? tabs : tabs.filter(tab => tab.linkProps.name !== 'MyItineraryPage');

  return userId ? (
    <LinkTabNavHorizontal
      className={classes}
      tabRootClassName={css.tab}
      tabs={filterTabs}
      skin="dark"
    />
  ) : null;
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { currentUserHasListings, currentUserHasOrders } = state.user;
  const { transactions } = state.MyItineraryPage;

  return {
    currentUserHasListings,
    currentUserHasOrders,
    transactions,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const UserNav = compose(
  withRouter,
  connect(mapStateToProps)
)(UserNavComponent);

export default UserNav;
