import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import unionWith from 'lodash/unionWith';
import { prepareText } from '../../util/data';

import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ListingItineraryTable,
  IconSpinner,
  Modal,
  Button,
  ModalInMobile,
  SearchMap,
} from '../../components';
import {
  declineSale,
  cancellationTransaction,
  cancelTransactionReset,
  declineSaleReset,
} from '../TransactionPage/TransactionPage.duck';
import { parse } from '../../util/urlHelpers';
import { TopbarContainer } from '..';

import {
  queryOwnTransactions,
  fetchTransaction,
  fetchTransactionReset,
} from './MyItineraryPage.duck';
import { searchListings } from '../SearchPage/SearchPage.duck';

import css from './MyItineraryPage.css';

const MODAL_BREAKPOINT = 768;
const { LatLng, LatLngBounds } = sdkTypes;

export const MyItineraryPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    onQueryOwnTransactions,
    transactions,
    queryTransactionsProgress,
    queryTransactionsError,
    onDeclineSale,
    declineSuccess,
    declineInProgress,
    onDeclineSaleReset,
    onManageDisableScrolling,
    location,
    mapListings,
    onCancellationTransaction,
    transaction,
    onFetchTransaction,
    fetchTransactionSuccess,
    fetchTransactionInProgress,
    fetchTransactionError,
    onFetchTransactionReset,
    sendTransactionSuccess,
    sendTransactionInProgress,
    sendTransactionError,
    onCancelTransactionReset,
    history,
  } = props;

  const pageRequest = 1;

  const [isCancelModalOpen, setIsCancelModalopen] = useState(false);
  const [currentTransactionId, setCurrentTransactionId] = useState(null);
  const [isTransitionAccept, setIsTransitionAccept] = useState(null);
  const [isSearchMapOpenOnMobile, setIsSearchMapOpenOnMobile] = useState(props.tab === 'map');
  const [activeListingId, setActiveListingId] = useState(null);
  const [activelabelId, setActivelabelId] = useState(null);
  const [requestCount, setRequestCount] = useState(0);
  const [listingType, setListingType] = useState('medium')
  const [startDate, setStartDate] = useState(null);
  const [transactionsRequestProgress, setTransactionRequestProgress] = useState(false);
  const [transactionRequest, setTransactionRequest] = useState(0)

  useEffect(() => {
    if (transactions.length === 0 && transactionRequest === 0) {
      currentUser && onQueryOwnTransactions(currentUser.id, pageRequest);
      setTransactionRequest(1);
    }
  }, [currentUser, transactions]);

  useEffect(() => {
    if (queryTransactionsProgress) {
      setTransactionRequestProgress(true)
    }
  }, [queryTransactionsProgress]);

  useEffect(() => {
    if (
      queryTransactionsProgress === false &&
      transactionsRequestProgress === true &&
      transactions.length === 0
    ) {
      history.push('/')
    }
  }, [queryTransactionsProgress, transactionsRequestProgress, transactions]);

  useEffect(() => {
    if (declineSuccess && currentUser) {
      setTimeout(() => {
        onQueryOwnTransactions(currentUser.id, pageRequest);
        onDeclineSaleReset();
        setIsCancelModalopen(false);
        setRequestCount(0);
      }, 1000);
    }
  }, [declineSuccess, currentUser]);

  useEffect(() => {
    if (sendTransactionSuccess && currentUser) {
      setTimeout(() => {
        onQueryOwnTransactions(currentUser.id, pageRequest);
        setRequestCount(0);
      }, 1000);
    }
  }, [sendTransactionSuccess, currentUser]);

  useEffect(() => {
    if (transaction && fetchTransactionSuccess && requestCount === 0) {
      onCancellationTransaction(transaction);
      setRequestCount(requestCount + 1);
    }
  }, [transaction, fetchTransactionSuccess]);

  useEffect(() => {
    if (isCancelModalOpen) {
      onFetchTransactionReset();
      onCancelTransactionReset();
      setIsCancelModalopen(false);
    }
  }, [transactions]);

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  const onMapMoveEnd = (viewportBoundsChanged, data) => {};

  const title = intl.formatMessage({ id: 'MyItineraryPage.title' });
  const isProvider = false;

  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(
    '?bounds=81.77060342%2C96.19903564%2C-81.46941277%2C-167.91778564&mapSearch=true',
    {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    }
  );

  const { origin } = searchInURL || {};

  const bounds = new LatLngBounds(
    new LatLng(37.517576, -68.70083),
    new LatLng(30.477399, -134.25908)
  );

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
  const shouldShowSearchMap = !isMobileLayout || (isMobileLayout && isSearchMapOpenOnMobile);

  const onCancelClick = (transactionId, isLastTransitionAccept, cancellationPolicy, date) => {
    setCurrentTransactionId(transactionId);
    setIsTransitionAccept(isLastTransitionAccept);
    setListingType(cancellationPolicy);
    setStartDate(date);
    setIsCancelModalopen(true);
  };

  const onMapIconClick = () => {
    setIsSearchMapOpenOnMobile(true);
  };

  const onActivateTransaction = id => {
    setActiveListingId(id);
  };

  const showActiveLabel = id => {
    setActivelabelId(id);
  };

  const onTransactionCancelClick = () => {
    if (isTransitionAccept) {
      onFetchTransaction(currentTransactionId);
    } else {
      onDeclineSale(currentTransactionId, isProvider);
    }
  };

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="MyItineraryPage" />
          <UserNav selectedPageName="MyItineraryPage" currentUser={currentUser} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.mainContainer}>
            <div className={css.contentContainer}>
              <div className={css.buttons}>
                <div className={css.mapIcon} onClick={onMapIconClick}>
                  <FormattedMessage id="MyItineraryPage.openMapView" className={css.mapIconText} />
                </div>
              </div>
              <h1 className={css.title}>{title}</h1>
              {transactions.length > 0 ? (
                <div className={css.description}>
                  <FormattedMessage id="MyItineraryPage.description" />
                </div>
              ) : (
                <div className={css.description}>
                  <FormattedMessage id="MyItineraryPage.noContent" />
                </div>
              )}
              {queryTransactionsError ? (
                <p className={css.error}>
                  <FormattedMessage id="MyItineraryPage.requestFailed" />
                </p>
              ) : null}
              {queryTransactionsProgress ? (
                <div className={css.conteinerSpinner}>
                  <IconSpinner className={css.spinner} />
                </div>
              ) : null}
              <ListingItineraryTable
                transactions={transactions}
                onCancelClick={onCancelClick}
                onActivateTransaction={onActivateTransaction}
                activelabelId={activelabelId}
              />
            </div>
            <Modal
              id="MyItineraryPage"
              isOpen={isCancelModalOpen}
              onClose={() => {
                setIsCancelModalopen(false);
                setRequestCount(0);
                onFetchTransactionReset();
                onCancelTransactionReset();
              }}
              onManageDisableScrolling={() => null}
              containerClassName={css.modalContainer}
            >
              <div className={css.cancellationModal}>
                {isTransitionAccept ? (
                  <p>{prepareText(listingType, startDate)}</p>
                ) : (
                  <p className={css.genericError}>
                    <FormattedMessage id="MyItineraryPage.isSureToCancel" />
                  </p>
                )}

                {sendTransactionError || fetchTransactionError ? (
                  <p className={css.error}>
                    <FormattedMessage id="MyItineraryPage.cancellationError" />
                  </p>
                ) : null}
                {sendTransactionSuccess || declineSuccess ? (
                  <p className={css.success}>
                    <FormattedMessage id="MyItineraryPage.cancellationSuccess" />
                  </p>
                ) : null}

                <div className={css.cancellationModalButtonHolder}>
                  <Button
                    className={css.cancellationModalButton}
                    onClick={onTransactionCancelClick}
                    inProgress={
                      declineInProgress || fetchTransactionInProgress || sendTransactionInProgress
                    }
                    disabled={
                      declineInProgress || fetchTransactionInProgress || sendTransactionInProgress
                    }
                  >
                    <FormattedMessage id="BookingBreakdown.yes" />
                  </Button>
                  <Button
                    className={classNames(
                      css.cancellationModalButton,
                      css.cancellationModalButtonNo
                    )}
                    onClick={() => {
                      setIsCancelModalopen(false);
                      setRequestCount(0);
                      onFetchTransactionReset();
                      onCancelTransactionReset();
                    }}
                    disabled={
                      declineInProgress || fetchTransactionInProgress || sendTransactionInProgress
                    }
                  >
                    <FormattedMessage id="BookingBreakdown.no" />
                  </Button>
                </div>
              </div>
            </Modal>
            {mapListings && (
              <ModalInMobile
                className={css.mapPanel}
                id="MyItineraryPage.map"
                isModalOpenOnMobile={isSearchMapOpenOnMobile}
                onClose={() => setIsSearchMapOpenOnMobile(false)}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.mapWrapper}>
                  {shouldShowSearchMap ? (
                    <SearchMap
                      reusableContainerClassName={css.map}
                      activeListingId={activeListingId}
                      bounds={bounds}
                      center={origin}
                      isSearchMapOpenOnMobile={isSearchMapOpenOnMobile}
                      location={location}
                      listings={mapListings || []}
                      onMapMoveEnd={onMapMoveEnd}
                      onCloseAsModal={() => {
                        onManageDisableScrolling('MyItineraryPage.map', false);
                      }}
                      messages={intl.messages}
                      onActivateLabel={showActiveLabel}
                      typePage={'MyItineraryPage'}
                    />
                  ) : null}
                </div>
              </ModalInMobile>
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

MyItineraryPageComponent.defaultProps = {
  currentUser: null,
};

const { bool, shape, func, string } = PropTypes;

MyItineraryPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,

  location: shape({
    search: string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    transactions,
    queryTransactionsProgress,
    queryTransactionsError,
    transactionRef,
    fetchTransactionSuccess,
    fetchTransactionInProgress,
    fetchTransactionError,
  } = state.MyItineraryPage;
  const {
    declineSuccess,
    declineInProgress,
    sendTransactionSuccess,
    sendTransactionInProgress,
    sendTransactionError,
  } = state.TransactionPage;
  const { searchMapListingIds } = state.SearchPage;

  const resultIds = transactions =>
    transactions &&
    transactions.map(l => {
      return l.relationships.listing.data.id;
    });

  const mapListings = getListingsById(
    state,
    unionWith(resultIds(transactions), searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  const getTransactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = getTransactions.length > 0 ? getTransactions[0] : null;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    transactions,
    queryTransactionsProgress,
    queryTransactionsError,
    declineSuccess,
    declineInProgress,
    mapListings,
    transaction,
    fetchTransactionSuccess,
    fetchTransactionInProgress,
    fetchTransactionError,
    sendTransactionSuccess,
    sendTransactionInProgress,
    sendTransactionError,
  };
};

const mapDispatchToProps = dispatch => ({
  onQueryOwnTransactions: (userId, page) => dispatch(queryOwnTransactions(userId, page)),
  onDeclineSale: (transactionId, role) => dispatch(declineSale(transactionId, role)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onCancellationTransaction: transaction => dispatch(cancellationTransaction(transaction)),
  onFetchTransaction: id => dispatch(fetchTransaction(id)),
  onFetchTransactionReset: () => dispatch(fetchTransactionReset()),
  onCancelTransactionReset: () => dispatch(cancelTransactionReset()),
  onDeclineSaleReset: () => dispatch(declineSaleReset()),
});

const MyItineraryPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(MyItineraryPageComponent);

MyItineraryPage.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: 100,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default MyItineraryPage;
