import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaInstagram.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        
        fillRule="evenodd"
        d="M7.76 1.672c-1.125 0-3.536-.09-4.55.311-.352.14-.613.312-.884.583a2.466 2.466 0 00-.583.884C1.342 4.464 1.432 6.875 1.432 8s-.09 3.536.311 4.55c.141.352.312.613.583.884.271.271.532.442.884.583 1.014.402 3.425.311 4.55.311s3.536.09 4.55-.311c.352-.141.613-.312.884-.583.271-.271.442-.532.583-.884.402-1.014.311-3.425.311-4.55s.09-3.536-.311-4.55a2.466 2.466 0 00-.583-.884 2.466 2.466 0 00-.884-.583c-1.014-.402-3.425-.311-4.55-.311z"
        clipRule="evenodd"
      ></path>
      <path
        
        fillRule="evenodd"
        d="M15.474 8c0 1.065.01 2.12-.05 3.184-.06 1.236-.342 2.33-1.246 3.235-.904.904-1.998 1.185-3.234 1.245-1.065.06-2.12.05-3.184.05-1.065 0-2.12.01-3.184-.05-1.236-.06-2.33-.341-3.235-1.245-.904-.904-1.185-2-1.245-3.235C.036 10.12.046 9.064.046 8c0-1.065-.01-2.12.05-3.184.06-1.236.341-2.33 1.245-3.234.904-.904 2-1.186 3.235-1.246C5.64.276 6.696.286 7.76.286c1.065 0 2.12-.01 3.184.05 1.236.06 2.33.342 3.234 1.246.904.904 1.186 1.998 1.246 3.234.06 1.065.05 2.12.05 3.184z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.718 8a3.952 3.952 0 01-3.958 3.958A3.952 3.952 0 013.803 8 3.952 3.952 0 017.76 4.042 3.952 3.952 0 0111.718 8z"
        clipRule="evenodd"
      ></path>
      <path
        
        fillRule="evenodd"
        d="M10.331 8A2.576 2.576 0 007.76 5.43 2.576 2.576 0 005.188 8a2.576 2.576 0 002.572 2.572A2.576 2.576 0 0010.33 8z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12.802 3.882a.922.922 0 01-.924.924.922.922 0 01-.924-.924c0-.513.412-.924.924-.924s.924.411.924.924z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

IconSocialMediaInstagram.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaInstagram;
