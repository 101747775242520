import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getAdminData } from '../../ducks/AdminData.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/localfnfFacebook-1200x630.jpg';
import twitterImage from '../../assets/localfnfTwitter-600x314.jpg';
import css from './LandingPage.css';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    isAuthenticated,
    onGetAdminData,
    adminData,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const adminPublicData = adminData && adminData.attributes && adminData.attributes.profile.publicData;
  const showBanner = adminPublicData && adminPublicData.showBanner;
  const textBanner = adminPublicData && adminPublicData.textBanner;

  useEffect(() => {
    onGetAdminData();
  }, []);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        {
          url: facebookImage,
          width: 1200,
          height: 630,
        },
      ]}
      twitterImages={[
        {
          url: `${config.canonicalRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {/* {isAuthenticated ? null : ( */}
            <div className={css.linksContainer}>
              <div className={css.linksWrapper}>
                <NamedLink name="AboutPage" className={css.link}>
                  <FormattedMessage id="LandingPage.toAboutPage" />
                </NamedLink>
                <NamedLink name="AboutCreatingAccountPage" className={css.link}>
                  <FormattedMessage id="LandingPage.toAboutCreatingAccountPage" />
                </NamedLink>
              </div>
            </div>
          {/* )} */}
          <div className={css.heroContainer}>
            {showBanner ? (
              <div className={css.banerContainer}>
                <h3 className={css.banerText}>{textBanner}</h3>
              </div>
            ) : null}
            <SectionHero
              className={showBanner ? css.heroWithBanner : css.hero}
              history={history}
              location={location}
              isAuthenticated={isAuthenticated}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  isAuthenticated: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { adminData, getAdminDataProgress, getAdminDataError } = state.AdminData;

  return {
    isAuthenticated,
    scrollingDisabled: isScrollingDisabled(state),
    adminData,
    getAdminDataProgress,
    getAdminDataError,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetAdminData: () => dispatch(getAdminData()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
