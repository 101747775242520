import React from 'react';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const SectionCancellationPolicyMaybe = props => {
  const { publicData } = props;
  const cancellationPolicyValue = publicData !== undefined ? publicData.cancellationPolicy : '';
  switch (cancellationPolicyValue) {
    case 'flexible':
      return(
        <div className={css.listingPageSection}>
          <h2 className={css.listingPageSectionTitle}>
            <FormattedMessage id="ListingPage.cancellationPolicy" />
          </h2>
          <p className={css.listingPageSectionContent}>
            <FormattedMessage id="EditListingPoliciesForm.cancellationPolicyFlexible" />
          </p>
        </div>
      );
    case 'medium':
      return(
        <div className={css.listingPageSection}>
          <h2 className={css.listingPageSectionTitle}>
            <FormattedMessage id="ListingPage.cancellationPolicy" />
          </h2>
          <p className={css.listingPageSectionContent}>
            <FormattedMessage id="EditListingPoliciesForm.cancellationPolicyMedium" />
          </p>
        </div>
      );
    case 'strict':
      return(
        <div className={css.listingPageSection}>
          <h2 className={css.listingPageSectionTitle}>
            <FormattedMessage id="ListingPage.cancellationPolicy" />
          </h2>
          <p className={css.listingPageSectionContent}>
            <FormattedMessage id="EditListingPoliciesForm.cancellationPolicyStrict" />
          </p>
        </div>
      );
    case 'non_refundable':
      return(
        <div className={css.listingPageSection}>
          <h2 className={css.listingPageSectionTitle}>
            <FormattedMessage id="ListingPage.cancellationPolicy" />
          </h2>
          <p className={css.listingPageSectionContent}>
            <FormattedMessage id="EditListingPoliciesForm.cancellationPolicyNonRefundable" />
          </p>
        </div>
      );
    default:
      return null;
  }
};

SectionCancellationPolicyMaybe.defaultProps = { className: null, rootClassName: null };

SectionCancellationPolicyMaybe.propTypes = {
  className: string,
  rootClassName: string,
  publicData: shape({
    cancellationPolicy: string,
  }),
};

export default SectionCancellationPolicyMaybe;
