import axios from 'axios';
import { storableError } from '../util/errors';

const API_URL = process.env.REACT_APP_API_NODE_URL ? process.env.REACT_APP_API_NODE_URL : '';

// ================ Action types ================ //

export const GET_ADMIN_REQUEST = 'app/AdminData/GET_ADMIN_REQUEST';
export const GET_ADMIN_SUCCESS = 'app/AdminData/GET_ADMIN_SUCCESS';
export const GET_ADMIN_ERROR = 'app/AdminData/GET_ADMIN_ERROR';

// ================ Reducer ================ //

const initialState = {
  adminData: null,
  getAdminDataProgress: false,
  getAdminDataError: null,
};

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ADMIN_REQUEST:
      return {
        ...state,
        getAdminDataProgress: true,
        getAdminDataError: null,
        adminData: null,
      };
    case GET_ADMIN_SUCCESS:
      return {
        ...state,
        getAdminDataProgress: false,
        getAdminDataError: null,
        adminData: payload.data,
      };
    case GET_ADMIN_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        qgetAdminDataProgress: false,
        getAdminDataError: payload,
        adminData: null,
      };

    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Selectors ================ //

// ================ Action creators ================ //

export const getAdminRequest = () => ({
         type: GET_ADMIN_REQUEST,
       });

export const getAdminSuccess = response => ({
         type: GET_ADMIN_SUCCESS,
         payload: { data: response },
       });

export const getAdminError = e => ({
         type: GET_ADMIN_ERROR,
         error: true,
         payload: e,
       });

export const getAdminData = () => async (dispatch, getState, sdk) => {

  dispatch(getAdminRequest());

  const url = `${API_URL}/api/get-admin-data`

  return axios
    .get(url)
    .then(response => {
      const adminData = response.data.data.data;
      dispatch(getAdminSuccess(adminData));
      return adminData;
    })
    .catch(e => {
      dispatch(getAdminError(storableError(e)));
    });
};
