import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import config from '../../config';
import css from './ShoppingCartHeader.css';
import { NamedLink } from '../index';

const ShoppingCartHeader = props => {
  const { className, format, shoppingCartDot, cartCount } = props;

  if (format === 'desktop') {
    return (
      <div className={css.shoppingCart}>
        {/* <img src="/static/icons/cart.svg" alt="cart-icon" className={css.cartIcon} /> */}
        <svg
          className={css.cartIcon}
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="17"
          fill="none"
          viewBox="0 0 21 17"
        >
          <path
            fill="#e52534"
            d="M19.99 2.125H5.826L5.523.644A.825.825 0 004.704 0H1.23a.408.408 0 00-.417.398v.797c0 .22.187.399.417.399h2.788l2.418 11.81c-.384.382-.62.9-.62 1.471 0 1.174.996 2.125 2.223 2.125 1.228 0 2.224-.951 2.224-2.125 0-.373-.103-.74-.298-1.063h5.043c-.196.323-.299.69-.298 1.063 0 1.174.995 2.125 2.223 2.125 1.228 0 2.223-.951 2.223-2.125 0-.602-.262-1.145-.683-1.532l.036-.158c.113-.497-.283-.966-.815-.966H7.892l-.326-1.594h10.853a.826.826 0 00.815-.628l1.57-6.906c.114-.497-.282-.966-.814-.966zm-3.057 11.953c.46 0 .834.358.834.797 0 .44-.374.797-.834.797-.46 0-.834-.358-.834-.797 0-.44.374-.797.834-.797zm-8.894 0c.46 0 .834.358.834.797 0 .44-.374.797-.834.797-.46 0-.833-.358-.833-.797 0-.44.374-.797.833-.797zm9.708-5.047H7.24L6.152 3.72h12.803L17.747 9.03z"
          ></path>
        </svg>
        <FormattedMessage id="TopbarDesktop.cart" />
        {shoppingCartDot}
        {cartCount}
      </div>
    );
  }
};

const { oneOf, string } = PropTypes;

ShoppingCartHeader.defaultProps = {
  className: null,
  format: 'desktop',
};

ShoppingCartHeader.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default ShoppingCartHeader;
