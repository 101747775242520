import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';

import css from './NeedForPage.css';
import image from './need-for-localfnf-1056.jpg';

const NeedForPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="Why LocalFnf | Localfnf"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'NeedForPage',
        description: 'Why LocalFnf',
        name: 'Need for page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Why LocalFnF</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p>
                We are a family of travelers this idea came about from our travel experiences and the inability 
                for us to fully immerse ourselves with the local culture and people.
              </p>
              <p>
                We would always find ourselves in situations where we didn’t 
                know where to eat which areas were safe and what tourist attractions to prioritize. 
              </p>
              <p>
                Although you can search the internet for some of this information there is nothing like knowing what a unique 
                and fulfilling experience a Local friend can bring in.
              </p>
            </div>
            <img className={css.coverImage} src={image} alt="Why LocalFnF" />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default NeedForPage;
