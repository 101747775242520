import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionHowItWorks.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const stripeLink = (
    <a href="https://stripe.com/" target="_blank" >
      <FormattedMessage id="SectionHowItWorks.part1Link" />
    </a>
  );

  const feedbackLink = (
    <a href="mailto:feedback@localfnf.com" >
      <FormattedMessage id="SectionHowItWorks.part2Link" />
    </a>
  );

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.title" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <h4 className={css.stepTitle}>
            {/* <span className={css.stepNumber}>
              <FormattedMessage id="SectionHowItWorks.part1Number" />
            </span> */}
            <FormattedMessage id="SectionHowItWorks.part1Title" />
          </h4>
          <p className={css.stepDescription}>
            <FormattedMessage id="SectionHowItWorks.part1Text" values={{ lineBreak: <br />, stripeLink }} />
          </p>
        </div>

        <div className={css.step}>
          <h4 className={css.stepTitle}>
            {/* <span className={css.stepNumber}>
              <FormattedMessage id="SectionHowItWorks.part2Number" />
            </span> */}
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </h4>
          <p className={css.stepDescription}>
            <FormattedMessage id="SectionHowItWorks.part2Text"  values={{lineBreak: <br />, feedbackLink}}/>
          </p>
        </div>

        <div className={css.step}>
          <h4 className={css.stepTitle}>
            {/* <span className={css.stepNumber}>
              <FormattedMessage id="SectionHowItWorks.part3Number" />
            </span> */}
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </h4>
          <p className={css.stepDescription}>
            <FormattedMessage id="SectionHowItWorks.part3Text" values={{feedbackLink}}/>
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
