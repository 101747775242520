import React, { useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';

import css from './ProfileReviews.css';

const Review = props => {
  const {
    review,
    intl,
    punctuality,
    safety,
    friendliness,
    recommendations,
    cleanliness,
    expectations,
    raiting,
    content,
  } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} />
      <div className={css.reviewWrapper}>
        <p className={css.reviewInfo}>
          <span>
            <UserDisplayName user={review.author} intl={intl} />
          </span>
          {/* <span className={css.infotext}>
            <FormattedMessage id="ReviewsPage.ratedUser" />
          </span> */}
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={raiting}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p>
        <ReviewRating
          rating={raiting}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />
        <p className={css.reviewContent}>{content}</p>
        {friendliness && <p className={css.reviewRaiting}>{`Friendliness ${friendliness}/5`}</p>}
        {punctuality && <p className={css.reviewRaiting}>{`Punctuality ${punctuality}/5`}</p>}
        {safety && <p className={css.reviewRaiting}>{`Safety ${safety}/5`}</p>}
        {recommendations && (
          <p className={css.reviewRaiting}>{`Recommendations ${recommendations}/5`}</p>
        )}
        {expectations && <p className={css.reviewRaiting}>{`Expectations ${expectations}/5`}</p>}
        {cleanliness && <p className={css.reviewRaiting}>{`Cleanliness ${cleanliness}/5`}</p>}
        <p className={css.date}>{dateString}</p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ProfileReviewsComponent = props => {
  const [itemsToShow, setItemsToShow] = useState(3);
  const [expanded, setExpanded] = useState(false);

  const { className, rootClassName, reviews, intl, reviewsWithCriteries } = props;
  const classes = classNames(rootClassName || css.root, className);

  const showMore = () => {
    if (itemsToShow === 3) {
      setItemsToShow(reviewsWithCriteries.length);
      setExpanded(true);
    } else {
      setItemsToShow(3);
      setExpanded(false);
    }
  };

  const showMorelink =
    reviews.length > 3 ? (
      <a className={css.showMorelink} onClick={showMore}>
        {expanded ? (
          <span>
            <FormattedMessage id="ReviewsPage.seeLess" />
          </span>
        ) : (
          <span>
            <FormattedMessage id="ReviewsPage.seeMore" />
          </span>
        )}
      </a>
    ) : null;

  return (
    <>
      <ul className={classes}>
        {reviews.slice(0, itemsToShow).map(r => {
          const id = r.id.uuid;
          const criteriesReview = reviewsWithCriteries.filter(
            rev => rev.ratingCriteries.reviewId === id
          )[0];

          const punctuality = criteriesReview ? criteriesReview.ratingCriteries.Punctuality : null;
          const safety = criteriesReview ? criteriesReview.ratingCriteries.Safety : null;
          const friendliness = criteriesReview
            ? criteriesReview.ratingCriteries.Friendliness
            : null;
          const recommendations = criteriesReview
            ? criteriesReview.ratingCriteries.Recommendations
            : null;
          const cleanliness = criteriesReview ? criteriesReview.ratingCriteries.Сleanliness : null;
          const expectations = criteriesReview
            ? criteriesReview.ratingCriteries.Expectations
            : null;
          const raiting = criteriesReview ? criteriesReview.reviewRating : null
          const content = criteriesReview ? criteriesReview.reviewContent : null

          return (
            <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
              <Review
                review={r}
                intl={intl}
                punctuality={punctuality}
                safety={safety}
                friendliness={friendliness}
                recommendations={recommendations}
                cleanliness={cleanliness}
                expectations={expectations}
                raiting={raiting}
                content={content}
              />
            </li>
          );
        })}
      </ul>
      {showMorelink}
    </>
  );
};

ProfileReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ProfileReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileReviews = injectIntl(ProfileReviewsComponent);

export default ProfileReviews;
