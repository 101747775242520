export const format = value => {
  if (!value) {
    return '';
  }

  const numbers = value.toString().replace(/[^\d]/g, '');

  return numbers;
};

export const parse = value => (value ? value.toString().replace(/\s/g, '') : '');
