import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { checkCaptcha } from '../../ducks/Auth.duck';
import { injectIntl } from '../../util/reactIntl';
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

class CaptchaComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
  }

  verifyCallback = (recaptchaToken) => {
    this.props.checkReCaptcha(recaptchaToken);
  }
  render() {
    return (
      <ReCaptcha
        sitekey={RECAPTCHA_SITE_KEY}
        action='signup'
        verifyCallback={this.verifyCallback}
      />
    );
  };
};

 const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({
  checkReCaptcha: (token) => dispatch(checkCaptcha(token))
});

const Captcha = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl
)(CaptchaComponent);

export default Captcha;
