import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SingleDatePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';

// Import moment from moment-timezone. 10-year range only.
// The full data included in moment-timezone dependency is mostly irrelevant
// and slows down the first paint.
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ListingTable,
  IconSpinner,
} from '../../components';
import {
  resetToStartOfDay,
  dateIsAfter,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
} from '../../util/dates';
import { TopbarContainer } from '../../containers';

import { queryOwnTransactions } from './MyCalendarPage.duck';
import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';

import css from './MyCalendarPage.css';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const ANCHOR_LEFT = 'left';

const MODAL_BREAKPOINT = 768;
const MAX_TIME_SLOTS_RANGE = 90;
const TODAY = new Date();
const timeZone = moment.tz.guess();

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

// Possible configuration options of React-dates
const defaultProps = {
  initialDate: null, // Possible initial date passed for the component
  value: null, // Value should keep track of selected date.

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  appendToBody: false,
  disableScroll: false,
  initialVisibleMonth: null,
  firstDayOfWeek: config.i18n.firstDayOfWeek,
  numberOfMonths: 1,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDate: false,
  renderCalendarInfo: null,
  hideKeyboardShortcutsPanel: true,
  daySize: 38,
  isRTL: false,

  // navigation related props
  navPrev: <PreviousMonthIcon />,
  navNext: <NextMonthIcon />,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},
  transitionDuration: 200, // milliseconds between next month changes etc.

  // day presentation and interaction related props
  renderCalendarDay: undefined, // If undefined, renders react-dates/lib/components/CalendarDay
  // day presentation and interaction related props
  renderDayContents: day => {
    return <span className="renderedDay">{day.format('D')}</span>;
  },
  enableOutsideDays: false,
  isDayBlocked: () => false,

  // outside range -><- today ... today+available days -1 -><- outside range
  isOutsideRange: day => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  },
  isDayHighlighted: () => {},
};

export const MyCalendarPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    onQueryOwnTransactions,
    transactions,
    queryTransactionsProgress,
    queryTransactionsError,
    currentUserHasListings,
    history,
    currentUserHasListingsSuccess,
    ...datePickerProps
  } = props;
  const page = 1;

  useEffect(() => {
    if (currentUserHasListingsSuccess !== null && currentUserHasListings === false) {
      history.push('/');
    }
  }, [currentUserHasListingsSuccess, currentUserHasListings]);

  const [dayTransactions, setDayTransactions] = useState([]);
  const [date, setDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(getMonthStartInTimeZone(TODAY, timeZone));

  useEffect(() => {
    currentUser && onQueryOwnTransactions(currentUser.id, page);
  }, [currentUser]);

  const title = intl.formatMessage({ id: 'MyCalendarPage.title' });

  const onDateChange = changeDate => {
    const selectedDate = changeDate instanceof moment ? changeDate.toDate() : null;

    if (changeDate === date) {
      setDate(null);
      setDayTransactions([]);
      return;
    }

    setDate(changeDate);

    const selectedTransactions = transactions
      ? transactions.filter(
          item =>
            moment(new Date(item.attributes.protectedData.start)).format('MM/DD/YYYY') ===
            moment(selectedDate).format('MM/DD/YYYY')
        )
      : [];

    setDayTransactions(selectedTransactions);
  };

  const isDayBlocked = transactions
    ? day =>
        !transactions.find(
          item =>
            moment(new Date(item.attributes.protectedData.start)).format('MM/DD/YYYY') ===
            day.format('MM/DD/YYYY')
        )
    : () => false;

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

  const Next = props => {
    const { currentMonth, timeZone } = props;
    const nextMonthDate = nextMonthFn(currentMonth, timeZone);

    return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
  };
  const Prev = props => {
    const { currentMonth, timeZone } = props;
    const prevMonthDate = prevMonthFn(currentMonth, timeZone);
    const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

    return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
  };

  function onMonthClick(monthFn) {
    setCurrentMonth(monthFn(currentMonth, timeZone));
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="MyCalendarPage" />
          <UserNav selectedPageName="MyCalendarPage" currentUser={currentUser} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.mainContainer}>
            <h1 className={css.title}>{title}</h1>
            <div className={css.description}>
              <FormattedMessage id="MyCalendarPage.description" />
            </div>
            {queryTransactionsError ? (
              <p className={css.error}>
                <FormattedMessage id="MyCalendarPage.requestFailed" />
              </p>
            ) : null}
            <div className={css.calendarContainer}>
              <div className={css.calendarWithSpinner}>
                <div className={css.calendar}>
                  <SingleDatePicker
                    {...datePickerProps}
                    focused={true}
                    onFocusChange={() => {
                      return { focused: true };
                    }}
                    date={date}
                    onDateChange={onDateChange}
                    isDayBlocked={isDayBlocked}
                    numberOfMonths={isMobileLayout ? 1 : 2}
                    onPrevMonthClick={() => onMonthClick(prevMonthFn)}
                    onNextMonthClick={() => onMonthClick(nextMonthFn)}
                    navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
                    navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
                  />
                </div>
                {queryTransactionsProgress ? (
                  <div className={css.conteinerSpinner}>
                    <IconSpinner className={css.spinner} />
                  </div>
                ) : null}
              </div>
              <div className={css.legend}>
                <FormattedMessage id="MyCalendarPage.legend" />
                <FormattedMessage id="MyCalendarPage.legendWhite" />
                <FormattedMessage id="MyCalendarPage.legendGray" />
                <FormattedMessage id="MyCalendarPage.legendUnderline" />
                <FormattedMessage id="MyCalendarPage.legendGreenCircle" />
              </div>
            </div>
            <ListingTable transactions={dayTransactions} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

MyCalendarPageComponent.defaultProps = {
  currentUser: null,
  ...defaultProps,
};

const { bool, shape, func } = PropTypes;

MyCalendarPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserHasListings, currentUserHasListingsSuccess } = state.user;
  const { transactions, queryTransactionsProgress, queryTransactionsError } = state.MyCalendarPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    transactions,
    queryTransactionsProgress,
    queryTransactionsError,
    currentUserHasListings,
    currentUserHasListingsSuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  onQueryOwnTransactions: (userId, page) => dispatch(queryOwnTransactions(userId, page)),
});

const MyCalendarPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(MyCalendarPageComponent);

export default MyCalendarPage;
