import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import axios from 'axios';
import store from 'store';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

const API_URL = process.env.REACT_APP_API_URL;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const SIGNUP_API_ERROR = 'app/Auth/SIGNUP_API_ERROR';

export const RECAPTCHA_REQUEST = 'app/Auth/RECAPTCHA_REQUEST';
export const RECAPTCHA_SUCCESS = 'app/Auth/RECAPTCHA_SUCCESS';
export const RECAPTCHA_ERROR   = 'app/Auth/RECAPTCHA_ERROR';

export const GET_USER_REQUEST = 'app/User/GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'app/User/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'app/User/GET_USER_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Environment ================ //

const RECAPTCHA_SECRET_KEY = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
const SEND_TOKEN_URL = 'https://www.google.com/recaptcha/api/siteverify';
const POSITIVE_SCORE = 0.5;
const REACT_APP_API_NODE_URL = process.env.REACT_APP_API_NODE_URL ? process.env.REACT_APP_API_NODE_URL : '';
// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // recaptcha
  recaptchaError: null,
  recaptchaInProgress: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };
    case SIGNUP_API_ERROR:
      return { ...state, signupInProgress: false, signupApiError: payload };
    case RECAPTCHA_REQUEST:
      return { ...state, recaptchaInProgress: true,  recaptchaError: null };
    case RECAPTCHA_SUCCESS:
      return { ...state, recaptcha: payload };
    case RECAPTCHA_ERROR:
      return { ...state, recaptchaInProgress: false, recaptchaError: payload };

    case GET_USER_REQUEST:
      return {
        ...state, getUserDataProgress: true, getUserDataError: null,
      };
    case GET_USER_SUCCESS:
      return {
        ...state, getUserDataProgress: false, getUserDataError: null,
      };
    case GET_USER_ERROR:
      return {
        ...state, getUserDataProgress: false, getUserDataError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const signupApiError = error => ({ type: SIGNUP_API_ERROR, payload: error,});

export const recaptchaRequest = () => ({ type: RECAPTCHA_REQUEST });
export const recaptchaSuccess = (value) => ({ type: RECAPTCHA_SUCCESS, payload: value, });
export const recaptchaError = error => ({ type: RECAPTCHA_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

export const getUserRequest = () => ({ type: GET_USER_REQUEST });
export const getUserSuccess = () => ({ type: GET_USER_SUCCESS });
export const getUserError = e => ({ type: GET_USER_ERROR, error: true, payload: e  });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => dispatch(loginError(storableError(e))));
};

export const loginAlt = (email, password) => (dispatch, getState, sdk) => {
  dispatch(loginRequest());

  return axios.post(`${API_URL}/api/v1/users/login`, { user: { email, password } })
    .then(response => {
      store.set('user', response.data.user);
      return dispatch(loginSuccess(response.data.user));
    })
    .then(() => dispatch(login(email, password)))
    .catch(e => {
      return dispatch(loginError(e));
    })
}

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      localStorage.clear();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());

  return sdk.currentUser
    .create(params)
    .then(() => dispatch(signupSuccess()))
    .then(() => dispatch(login(params.email, params.password)))
    .catch(e => {
      dispatch(signupError(storableError('EMAIL FALSE')));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

export const checkUserByEmail = email => (dispatch, getState, sdk) => {
  dispatch(getUserRequest());

  const url = `${REACT_APP_API_NODE_URL}/api/get-user-by-email/?email=${email}`
  return axios
    .get(url)
    .then(response => {
      return dispatch(getUserSuccess());
    })
    .catch(e => {
      dispatch(getUserError(storableError(e)));
    })
}

export const signupAlt = params => (dispatch, getState, sdk) => {
  dispatch(signupRequest());
  const { lastName: last_name, firstName: first_name, password, email, protectedData: { phoneNumber: phone_number } } = params;

  const requestParams = {
    user: {
      email,
      password,
      first_name,
      last_name,
      phone_number
    }
  };

  return axios.post(`${API_URL}/api/v1/users`, requestParams)
    .then(() => {
      dispatch(signupSuccess())
    })
    .catch(e => {
      dispatch(signupApiError(true));
      throw new Error(e);
     })
    .then(() => dispatch(signup(params)))
    .then(() => dispatch(loginAlt(email, password)))
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};


export const checkCaptcha = params => (dispatch, getState) => {
  if(params !== undefined){
    dispatch(recaptchaRequest());
    const url = `${SEND_TOKEN_URL}?secret=${RECAPTCHA_SECRET_KEY}&response=${params}`;
    fetch(PROXY_URL + url)
    .then(response => response.json())
    .then((contents) =>{
      if(contents.success === true && contents.score > POSITIVE_SCORE){
        dispatch(recaptchaSuccess(true));
      }else{
        dispatch(recaptchaSuccess(false));
      }})
    .catch(e => dispatch(recaptchaError(storableError(e))));
  }
};
