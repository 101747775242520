import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput, PrimaryButton, Button, FieldCustomPhoneNumberInput } from '../../components';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';

import css from './SmsVerificationFormNew.css';
import classNames from 'classnames';

class SmsVerificationFormComponent extends Component {
  render() {
    const {
      intl
    } = this.props;


    const verificationCodeLabel = intl.formatMessage({
      id: 'SmsVerificationForm.verificationCodeLabel',
    });

    const verificationCodePlaceholder = intl.formatMessage({
      id: 'SmsVerificationForm.verificationCode',
    });
    const verificationCodeRequiredMessage = intl.formatMessage({
      id: 'SmsVerificationPage.codedRequired',
    });

    const verificationQuestion = intl.formatMessage({
      id: 'SmsVerificationPage.question',
    });

    const anotherOne = intl.formatMessage({
      id: 'SmsVerificationPage.anotherOne',
    });

    const resendButton = intl.formatMessage({
      id: 'SmsVerificationPage.resend',
    });

    const verificationCodeRequired = validators.required(verificationCodeRequiredMessage);

      // phoneNumber
      const phoneNumberRequiredMessage = intl.formatMessage({
        id: 'SignupForm.phoneRequired',
      });
      const phoneNumberMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.phoneMinLength',
        },
        {
          minLength: validators.PHONE_MIN_LENGTH,
        }
      );
      const phoneNumberMinLength = validators.minLength(
        phoneNumberMinLengthMessage,
        validators.PHONE_MIN_LENGTH
      );

      const phoneNumberRequired = validators.requiredStringNoTrim(phoneNumberRequiredMessage);
      const phoneNumberValidators = validators.composeValidators(
        phoneNumberRequired,
        phoneNumberMinLength,
      );

    
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {

          const {
            handleSubmit,
            verificationStatusError,
            verificationRequestError,
            values,
            sendSms,
            smsRequestData,
            inProgress,
            onChange,
          } = formRenderProps;
          
          const errorMesage = (
            <FormattedMessage id="SmsVerificationPage.incorrectVerificationCode" />
          )

          const bottomWrapperClasses = classNames(
            css.bottomWrapper,
            {[css.bottomWrapperDisabled]: !values.token}
          );

          const submitInProgress = inProgress;

          return (
            <Form className={css.root} onSubmit={handleSubmit}>
              
              { verificationRequestError && <p className={css.error}> {verificationRequestError} </p> }           

              <div className={css.phoneNumber}>
                <span className={css.phoneNumberLabel}>
                  <FormattedMessage id="SmsVerificationPage.phoneNumber" />
                </span>
                <FieldCustomPhoneNumberInput 
                  id="phoneNumber"
                  name="phoneNumber"
                  validate={phoneNumberValidators}
                />
              </div>


              <div className={bottomWrapperClasses}>
                
                {smsRequestData && (
                  <>
                    <FieldTextInput
                      label={verificationCodeLabel}
                      className={css.password}
                      type="password"
                      id="token"
                      name="token"
                      placeholder={verificationCodePlaceholder}
                      validate={verificationCodeRequired}
                      autoComplete="off"
                    />

                    
                    <div className={css.resendSmsMessage} >
                      {verificationQuestion} <span className={css.activeResendSms} onClick={sendSms}> {resendButton} </span> {anotherOne}
                    </div>
                  </>
                )}

                <PrimaryButton className={css.submitBtn} inProgress={submitInProgress} type="submit">
                  {smsRequestData ? 
                    <FormattedMessage id="SmsVerificationForm.smsVerify" /> 
                    : 
                    <FormattedMessage id="SmsVerificationPage.sendCode" />
                  }
                </PrimaryButton>
                
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SmsVerificationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SmsVerificationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,
};

const SmsVerificationFormNew = compose(injectIntl)(SmsVerificationFormComponent);

SmsVerificationFormNew.displayName = 'SmsVerificationForm';

export default SmsVerificationFormNew;
