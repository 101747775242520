import React, { Component, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureListing, ensureCurrentUser, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { ListingFavouritesForm } from '../../forms';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { NamedLink, ResponsiveImage } from '../../components';

import css from './FavoriteListingCard.css';

const { Money } = sdkTypes;
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'FavoriteListingCard.unsupportedPrice' },
        { currency: price ? price.currency : '' }
      ),
      priceTitle: intl.formatMessage(
        { id: 'FavoriteListingCard.unsupportedPriceTitle' },
        { currency: price ? price.currency : '' }
      ),
    };
  }
  return {};
};

export const FavoriteListingCardComponent = props => {

  const [index, setIndex] = useState(0);

  const { 
    className, 
    rootClassName, 
    intl, 
    listing, 
    onUpdateProfile,
    currentUser,
  } = props;

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const save = async values => {
    await sleep(1000)
  };
  const user = ensureCurrentUser(currentUser);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const listingFavourite =
    currentListing &&
      user.attributes.profile.publicData &&
      (user.attributes.profile.publicData.listingFavourite !== undefined)
      ? user.attributes.profile.publicData.listingFavourite : [];

  const id = currentListing.listingId;
  // const id = currentListing.id.uuid;

  let title = currentListing ? currentListing.title : '';
  let priceAmount = currentListing ? currentListing.priceAmount : null;
  let priceCurrency = currentListing ? currentListing.priceCurrency : '';
  const price = new Money(priceAmount, priceCurrency);
  const authorName = currentListing.authorName;

  const init = listingFavourite ? listingFavourite.filter(l => l.listingId === id) : [];
  const slug = createSlug(title);

  const { formattedPrice, priceTitle } = priceData(price, intl);

  return (
    <div className={classes}>
      <div className={css.favouriteButton}>
        <ListingFavouritesForm
          format="deleteFavouriteButton"
          className={css.checkbox}
          initialValues={{
            [`favourite_${id}`]: init[0] ? init[0][`favourite_${id}`] : false
          }}
          onSubmit={values => {
            save(values);
            let currentListingId = Object.keys(values)[0];
            let currentListingValue = values[`favourite_${id}`];
            currentListingId = currentListingId.replace('favourite_', '');
            const hasCurrentListing = listingFavourite.filter(l => l.listingId === currentListingId).length > 0;
            if (hasCurrentListing && !currentListingValue) {
              const removeCurrentListing = listingFavourite.filter(l => l.listingId !== currentListingId);
              onUpdateProfile({
                publicData: {
                  listingFavourite: removeCurrentListing
                }
              });
            }
          }}
          onChange={values => {
          }}
          listingId={id}
        />
      </div>
      <NamedLink className={css.listingCardItem} name="ListingPage" params={{ id, slug }}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <div 
              style={{ backgroundImage: `url(${currentListing.listingImages})` }}
              className={css.listingImage}
            >
            </div>
          </div>
        </div>
        <div className={css.info}>
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
            </div>
          </div>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.perUnit}>
              <FormattedMessage id="ListingCard.perGuest" />
            </div>
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

FavoriteListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  categoriesConfig: config.custom.categories,
};

FavoriteListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(FavoriteListingCardComponent);
