const facebookId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

export const fbqHelper = () => {
  if (!facebookId) {
    return;
  }
  if (typeof window === 'undefined' && typeof document === 'undefined') {
    return;
  }

  const ReactPixel = require('react-facebook-pixel');

  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  
  ReactPixel.default.init(facebookId, advancedMatching, options);

  return ReactPixel.default;
};
