import React from 'react';
import { FieldSelect } from '../../components';

import css from './FieldDateAndTimeInput.css';


const CustomGuestSelect = props => {
  const { name, id, intl, numberOfGuestsFrom, numberOfGuestsTo, maxNumberOfCuests } = props;
  const placeholder = intl.formatMessage({
    id: 'BookingTimeForm.numberOfGuestsPlaceholder',
  });

  let start = parseInt(numberOfGuestsTo);
  let end = parseInt(numberOfGuestsFrom);
  let result = [];
  
  if (start === parseInt(maxNumberOfCuests)) {
    while (start >= end) {
      result.push(start--);
    }
  } else {
    while (start > 0) {
      result.push(start--);
    }
  }

  const numberOfGuests = result.reverse()

  return (
    <FieldSelect className={css.guestSelect} name={name} id={id}>
      <option key='placeholder' value='' className={css.hideOptions}>
        {placeholder}
      </option>
      {numberOfGuests.map(i => (
        <option key={i} value={i}>
          {i}
        </option>
      ))}
    </FieldSelect>
  );
};

export default CustomGuestSelect;
