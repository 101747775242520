import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import config from '../../config';
import css from './Favorite.css';
import IconFavorite from './IconFavorite';
import { NamedLink } from '../index';

const Favorite = props => {
  const { className, format} = props;

  if (format === 'desktop') {
    return (
      <div className={css.favorite}>
          <IconFavorite />
          <FormattedMessage id= "TopbarDesktop.favorites" />
      </div>
    )
  }
};

const { oneOf, string } = PropTypes;

Favorite.defaultProps = {
  className: null,
  format: 'desktop',
};

Favorite.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Favorite;
