import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';
import Pdf from './TOC_About_Localfnf_1.1.pdf';

import css from './AboutCreatingAccountPage.css';
import image from './about-creating-account-1056.jpg';

const AboutCreatingAccountPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="About creating an account | Localfnf"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutCreatingAccountPage',
        description: 'About creating an account',
        name: 'About creating an account page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AboutCreatingAccountPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>About creating an account</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <div className={css.containerLink}>
                <a className={css.linkPdf} href={Pdf} target="_blank">
                  Please use this link for directions to register as a host or a guest.
                </a>
              </div>
              <p>
                As a host or guest include a description of yourself, this helps us to provide
                information about a person before they connect for an activity.
              </p>
              <p>
                Once you provide your information our Admin will review and approve after
                authentication.
              </p>
              <p>
                Once your account is authenticated and approved you will be notified by an email.
              </p>
              <p>
                For any questions please reach out to{' '}
                <a href={`mailto:customersupport@localfnf.com?subject=Request to support`}>
                  Customersupport@localfnf.com
                </a>
              </p>
            </div>
            <img className={css.coverImage} src={image} alt="About creating an account" />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutCreatingAccountPage;
