import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.css';

const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg className={className} width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <path
        className={checkedClassName || css.checked}
        fill="#3C5A9A"
        stroke="#3C5A9A"
        strokeWidth="1.5"
        d="M13.752 3.504a1.25 1.25 0 00-1.25-1.253L3.5 2.25c-.69 0-1.25.56-1.25 1.25v9c0 .69.56 1.25 1.25 1.25h8.977a1.25 1.25 0 001.25-1.246l.025-9z"
      ></path>
      <g clipPath="url(#clip0)">
        <path
          fill="#fff"
          d="M3 7.787l3.424 3.088L13 4.963l-.944-.838-5.632 5.063-2.493-2.245L3 7.787z"
        ></path>
      </g>
      <path
        className={boxClassName || css.box}
        stroke="#979797"
        strokeWidth="1.5"
        d="M13.752 3.504a1.25 1.25 0 00-1.25-1.253L3.5 2.25c-.69 0-1.25.56-1.25 1.25v9c0 .69.56 1.25 1.25 1.25h8.977a1.25 1.25 0 001.25-1.246l.025-9z"
      ></path>
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
