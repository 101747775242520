import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  NamedLink,
} from '../../components';

import css from './TermsOfService.css';
import Pdf from '../../containers/AboutCreatingAccountPage/TOC_About_Localfnf_1.1.pdf';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>

      <p>
        By registering as a member you agree that you have read all of the rules and regulations pertaining to
        the use of the site.
      </p>
      <p>
        Things you refrain from doing. Do not break the law, don’t lie, and don’t offer prohibited items,
        do not break any rules, do not use profanity. We expect you will always maintain your integrity and decorum.
      </p>
      <p>
        <b>
          By using the site you agree and have read all of the rules pertaining to the use of the site. <NamedLink name="AboutCreatingAccountPage" className={css.link}>
            About the account, payment policy, cancellation etc.
          </NamedLink>
        </b>
      </p>

      <h4>
        Services
      </h4>
      <p>
        This is an online marketplace, you need to be 18 years or older to be a Host or Reserve an activity as a Guest.
        As a Host you can post any activity, which is legal, and not a risk to anyone’s life.
      </p>
      <p>
        Localfnf is not liable for rules broken by a Host or Guest. If you are a Guest please follow
        the rules set by the Host.
      </p>
      <p>
        Both Hosts and Guests are asked to create a profile that provides personal information, and
        communicate with each other through the Site. Guests make Reservations and Hosts have a
        booking for their listings. Hereinafter defined as the Reservation/Bookings.
      </p>
      <p>
        Like any other Market Place we have the right to terminate access to your account in the event we see
        an illegal activity. We have the right to terminate or discontinue the site at any point, as
        a whole or a part without notice. Localfnf cannot be held liable for such an occurrence.
      </p>

      <h4>
        Indemnification
      </h4>
      <p>
        You will not hold Localfnf liable or its directors, officers, employees, representatives and agents for claims,
        actions, liabilities, losses, costs, damages or expenses whatsoever (including reasonable attorneys’ fees)
        (“Damages“) asserted against, imposed upon or incurred by Localfnf or any of its Related Parties
        resulting from or arising out of any breach of these Terms by you or arising and related to
        your use of the Services.
      </p>

      <h4>
        Third-Party Links & Ads
      </h4>
      <p>
        The Site may contain links to third-party websites and/or services, and/or display advertisements for
        third parties (collectively, “Third-Party Links & Ads”). Such Third-Party Links & Ads are not under
        the control of Localfnf, and we are not responsible for them. Localfnf provides access to these
        Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor,
        endorse, warrant, or make any representations with respect to Third-Party Links & Ads.
        You use all Third-Party Links & Ads at your own risk, and should apply a suitable level
        of caution and discretion in doing so. When you click on any of the Third-Party Links & Ads,
        the applicable third party’s terms and policies apply, including the third party’s privacy
        and data gathering practices.
      </p>
      <p>
        Guest and Endorsement. Each Host is solely responsible for any and all of its Listing Content.
        Localfnf does not endorse any Hosts, Guests, or offers and/or services listed or advertised on the Site.
        Because we do not control Content of the Host or Guest (profile), you acknowledge and agree that we
        are not responsible for any Content, whether provided by you or by others. We make no guarantees
        regarding the accuracy, currency, suitability, or quality of any Content posted in a listing.
        Your interactions with other Site Guest are solely between you and such Guest and it is your
        own responsibility to check the accuracy and truthfulness of that User's information.
        You agree that Localfnf will not be responsible for any loss or damage incurred as the
        result of any such interactions. If there is a dispute between you and any Site User,
        we are under no obligation to become involved, but reserve the right to should we chose.
      </p>
      <p>
        Release. You hereby release and discharge Localfnf (and our officers, employees, agents, successors, and assigns)
        from, and hereby waive, any claim, controversy, demand, right, obligation, liability,
        action and cause of action of every kind and nature (including personal injuries, death, and property damage),
        that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site.
      </p>

      <h4>
        Social Media
      </h4>
      <p>
        Our services may integrate with third party social applications and/or services (the "Social Apps")
        and other related tools which let you share information and actions that you take on our Site by use of
        the Social Apps, and vice versa (for example, an action you take on our Site may be published on
        Facebook if your account has been set-up this way). Your use of such features enables the sharing of
        information with your friends or the public, depending on the settings you establish with the Social Apps
        and our Site. Please check the privacy policies of those Social Apps for more information about how
        they handle the data you share through them.
      </p>
      <p>
        You will be liable if you provide harm to Localfnf’s reputation or defame the site in any way or form
        by publishing incorrect or harming information about Localfnf via Social Media.
      </p>

      <h4>
        Disclaimers
      </h4>
      <p>
        The Site is provided on an “as-is” and “as-available” basis, and Localfnf (including any management members,
        board members, employees, suppliers, etc.) expressly disclaim any and all warranties and conditions of any
        thing, whether express, implied, or statutory, including all warranties or conditions of merchantability,
        fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement. We (and our partners)
        make no warranty that the Site will meet your requirements, will be available on an uninterrupted, timely,
        secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete,
        legal, or safe. Localfnf does not guarantee the quality, suitability, reliability, conduct, safety or
        ability of Hosts and/or Guest, and/or the interactions with them. You agree that the entire risk
        arising out of use of the Market Place, and any Service requested from a Host through use of the Site,
        are and remain solely with you, to the maximum extent permitted under applicable law. Localfnf does not
        warranty the site or its performance of any kind.
      </p>
      <p>
        In no event shall Localfnf (including any management members, officers, employees, agents, etc.) be
        liable to you for any damages whatsoever, including without limitation, indirect, incidental, special,
        punitive, and/or consequential damages, arising out of or in connection with your use of the Site or
        the Services, including but not limited to the quality, accuracy, or utility of the information provided
        as part of or through the Site, whether the damages are foreseeable and whether or not Localfnf has
        been advised of the possibility of such damages. Localfnf assumes no responsibility for any error, omission,
        interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or
        destruction or unauthorized access to, or alteration of, any User Content. Localfnf is not responsible
        for any problems or technical malfunction of any telephone or cellular phone network or lines, computer
        online systems, servers or providers, computer equipment, software, failure of any email due to technical
        problems or traffic congestion on the Internet or on the Site, including any injury or damage to Guest or
        to any person´s mobile device or computer related to or resulting from participation or downloading materials
        in connection with the Site. UNDER NO CIRCUMSTANCES SHALL LOCALFNF BE RESPONSIBLE FOR ANY LOSS OR DAMAGE,
        INCLUDING PERSONAL INJURY OR DEATH, RESULTING FROM USE OF THE SITE, FROM ANY USER CONTENT POSTED ON OR
        THROUGH THE SITE, OR FROM THE CONDUCT OF ANY GUEST OF THE SITE, WHETHER ONLINE OR OFFLINE. Localfnf’s
        responsibilities are limited to the providing of the Services as a marketplace platform. Localfnf is
        not an agency of any kind and does not operate as one. Localfnf does not manage or control the Hosts
        or Guests and/or their Activity/Event offers, nor do we undertake to monitor, review or filter the
        Hosts or Guests. Localfnf does not directly operate, sell, resell, provide or manage any transport
        and/or other related activities. Furthermore, Localfnf is not a party to any agreement entered
        into between Guests and Hosts, nor is Localfnf an agent, insurer or representative of either side.
        Consequently, the uses of Activity/Event services provided by the Hosts are at the sole responsibility
        of the Guests, and the providing of Experiences to the Guest is at the sole responsibility of the Host.
        By making a reservation through Localfnf, Guests and Hosts enter into a direct contractual relationship.
        From the point at which Guests make a reservation, Localfnf acts solely as an intermediary between Guests
        and Hosts. Localfnf is not a party to any agreement between Guests and Hosts and is not liable for any
        (attributable) failure to perform or other damages arising out of this relationship.
      </p>
      <p>
        Please refer to Cancellation Policy details as in <a href={Pdf} target="_blank">
          Creating an Account.
        </a>
      </p>

      <h4>
        Miscellaneous
      </h4>
      <p>
        Modification of the Terms. These Terms may be revised and updated from time to time.
        It is your responsibility to ensure that you remain compliant with the current Terms.
      </p>
      <p>
        Governing Law and Jurisdiction. These Terms shall be governed by the law of the land. (US)
      </p>
      <p>
        No Class Actions. You and Localfnf acknowledge and agree that, to the fullest extent permitted by law,
        we are each waiving the right to participate as a plaintiff or class member in any purported class
        action lawsuit, class-wide arbitration, private attorney-general action, or any other representative
        proceeding as to all disputes.
      </p>
      <p>
        Electronic Communications. The communications between you and Localfnf may be through electronic means,
        whether you use the Site or send us emails, or whether we post notices on the Site or communicate with
        you via email. For contractual purposes, you (a) consent to receive communications from us in an
        electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures,
        and other communications that we provide to you electronically satisfy any legal requirement that
        such communications would satisfy if it were in hardcopy writing. The foregoing does not affect
        your non-waivable rights.
      </p>
      <p>
        Your relationship to Localfnf is that of an independent contractor, and neither party is an agent or partner
        of the other. These Terms, and your rights and obligations herein, may not be assigned, subcontracted,
        delegated, or otherwise transferred by you without our prior written consent, and any attempted assignment,
        subcontract, delegation, or transfer in violation of the foregoing will be null and void. Localfnf may
        freely assign these Terms. The terms and conditions set forth in these Terms shall be binding upon assignees.
      </p>
      <p>
        Copyright/Trademark Information. All trademarks, logos and service marks (“Marks”) displayed on the
        Site are our property or the property of other third parties who have permitted us to use them.
        You are not permitted to use these Marks without our prior written consent or the consent of
        such third party, which may own the Marks.
      </p>
      <p>
        Currently users will be unable to post in any language other than the English language.
        In the event we start accepting posts in other languages, these Terms and conditions in
        English will apply to all.
      </p>

      <p>
        <b>Contact Information:</b> <a href="mailto:info@Localfnf.com">info@Localfnf.com</a>
      </p>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
