import React, { useState, useEffect } from 'react';
import { bool, func, array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconSpinner } from '../../components';

import css from './ShoppingCartTable.css';

const ShoppingCartTable = props => {
  const {
    editClick,
    deleteClick,
    confirmClick,
    userCartList,
    disableButtonConfirm,
    submitInProgress,
    idInProgress,
    onActivateListing,
    activelabelId,
    speculateErrorMessage,
    initiateOrderErrorMessage,
  } = props;
  const [total, setTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [otherCharges, setOtherCharges] = useState(0);
  const [selectedId, setSelectedId] = useState(null)

  const isError = speculateErrorMessage || initiateOrderErrorMessage;

  const tabledataHeader = [
    '№',
    'Date',
    'Time',
    'Listing name',
    'Price/guest',
    'Guests',
    'In total',
    '',
  ];

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let sum = userCartList.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.price / 100) * currentValue.seats * currentValue.units;
  }, 0);

  useEffect(() => {
    setTotal(sum.toFixed(2));
  }, [sum]);

  const sortUserCartList = userCartList.sort((a, b) => {
    return +new Date(a.bookingDatesStart) > +new Date(b.bookingDatesStart)
      ? 1
      : +new Date(b.bookingDatesStart) > +new Date(a.bookingDatesStart)
      ? -1
      : 0;
  }
  );

  const handleSubmit = (listing) => {
    setSelectedId(listing.idForCart)
    confirmClick(listing);
  }

  return (
    <div>
      {windowDimensions < 1550 ? (
        <div className={css.cardMobile}>
          {sortUserCartList.map((listing, index) => {
            const price = (listing.price / 100).toFixed(2);
            const seats = listing.seats;
            const units = listing.units;
            const inTotal = price * seats * units;
            const date = new Date(listing.bookingDatesStart);
            const time = new Date(listing.bookingDatesStart).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            });
            const bookingDate = `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;
            const name = listing.listingName;
            const guest = seats;

            const isActive = activelabelId ? activelabelId.uuid === listing.id : false;

            return (
              <div
                className={isActive ? css.cardContainerMobileActive : css.cardContainerMobile}
                key={listing.idForCart}
                onMouseEnter={() => {
                  onActivateListing({ uuid: `${listing.id}`, _sdkType: 'UUID' });
                }}
                onMouseLeave={() => {
                  onActivateListing(null);
                }}
              >
                <div className={css.rowContainerMobile}>
                  <span className={css.itemFistColumnMobile}>{tabledataHeader[0]}</span>
                  <span className={css.itemSecondColumnMobile}>{index + 1}</span>
                </div>
                <div className={css.rowContainerMobile}>
                  <span className={css.itemFistColumnMobile}>{tabledataHeader[1]}</span>
                  <span className={css.itemSecondColumnMobile}>{bookingDate}</span>
                </div>
                <div className={css.rowContainerMobile}>
                  <span className={css.itemFistColumnMobile}>{tabledataHeader[2]}</span>
                  <span className={css.itemSecondColumnMobile}>{time}</span>
                </div>
                <div className={css.rowContainerMobile}>
                  <span className={css.itemFistColumnMobile}>{tabledataHeader[3]}</span>
                  <span className={css.itemSecondColumnMobile}>{name}</span>
                </div>
                <div className={css.rowContainerMobile}>
                  <span className={css.itemFistColumnMobile}>{tabledataHeader[4]}</span>
                  <span className={css.itemSecondColumnMobile}>{`${price * units}$`}</span>
                </div>
                <div className={css.rowContainerMobile}>
                  <span className={css.itemFistColumnMobile}>{tabledataHeader[5]}</span>
                  <span className={css.itemSecondColumnMobile}>{guest}</span>
                </div>
                <div className={css.rowContainerMobile}>
                  <span className={css.itemFistColumnMobile}>{tabledataHeader[6]}</span>
                  <span className={css.itemSecondColumnMobile}>{`${inTotal.toFixed(2)}$`}</span>
                </div>
                <div className={css.rowContainerButtonMobile}>
                  <div className={css.buttonEditDelete} onClick={() => editClick(listing)}>
                    <img src="/static/icons/edit.svg" alt="edit-icon" className={css.buttonImage} />
                    <span className={css.buttonEditText}>
                      <FormattedMessage id="ShoppingCartTable.edit" />
                    </span>
                  </div>
                  <button
                    className={css.buttonEditDelete}
                    onClick={() => deleteClick(listing.idForCart)}
                  >
                    <img
                      src="/static/icons/trash.svg"
                      alt="trash-icon"
                      className={css.buttonImage}
                    />
                    <span className={css.buttonDeleteText}>
                      <FormattedMessage id="ShoppingCartTable.delete" />
                    </span>
                  </button>
                  <button
                    className={disableButtonConfirm ? css.buttonConfirmDisabled : css.buttonConfirm}
                    onClick={() => handleSubmit(listing)}
                    disabled={disableButtonConfirm || submitInProgress}
                  >
                    {submitInProgress && idInProgress === listing.idForCart ? (
                      <IconSpinner className={css.spinner} />
                    ) : (
                      <span className={css.buttonConfirmText}>
                        <FormattedMessage id="ShoppingCartTable.confirm" />
                      </span>
                    )}
                  </button>
                </div>
                {listing.idForCart === selectedId && speculateErrorMessage}
                {listing.idForCart === selectedId && initiateOrderErrorMessage}
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {speculateErrorMessage}
          {initiateOrderErrorMessage}
          <table className={css.table}>
            <tbody>
              <tr className={css.tableHeadingRow}>
                {tabledataHeader.map((item, index) => {
                  return (
                    <th
                      className={index === 0 ? css.itemContainerFirst : css.itemContainer}
                      key={item}
                    >
                      <span className={css.itemHeader}>{item}</span>
                    </th>
                  );
                })}
              </tr>
              {sortUserCartList.map((listing, index) => {
                const price = (listing.price / 100).toFixed(2);
                const seats = listing.seats;
                const units = listing.units;
                const inTotal = price * seats * units;
                const date = new Date(listing.bookingDatesStart);
                const time = new Date(listing.bookingDatesStart).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                });
                const bookingDate = `${date.getMonth() +
                  1}.${date.getDate()}.${date.getFullYear()}`;
                const name = listing.listingName;
                const guest = seats;

                const isActive = activelabelId ? activelabelId.uuid === listing.id : false;

                return (
                  <tr
                    className={
                      isActive
                        ? css.tableDataRowActive
                        : isError && listing.idForCart === selectedId
                        ? css.tableDataRowError
                        : css.tableDataRow
                    }
                    key={listing.idForCart}
                    onMouseEnter={() => {
                      onActivateListing({ uuid: `${listing.id}`, _sdkType: 'UUID' });
                    }}
                    onMouseLeave={() => {
                      onActivateListing(null);
                    }}
                  >
                    <th className={css.itemContainerFirst}>
                      <span className={css.itemTable}>{index + 1}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{bookingDate}</span>
                    </th>
                    <th className={css.itemContainerThird}>
                      <span className={css.itemTable}>{time}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{name}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{`${price * units}$`}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{guest}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{`${inTotal.toFixed(2)}$`}</span>
                    </th>
                    <th className={css.itemContainerButton}>
                      <div className={css.buttonEditDelete} onClick={() => editClick(listing)}>
                        <img
                          src="/static/icons/edit.svg"
                          alt="edit-icon"
                          className={css.buttonImage}
                        />
                        <span className={css.buttonEditText}>
                          <FormattedMessage id="ShoppingCartTable.edit" />
                        </span>
                      </div>
                      <button
                        className={css.buttonEditDelete}
                        onClick={() => deleteClick(listing.idForCart)}
                      >
                        <img
                          src="/static/icons/trash.svg"
                          alt="trash-icon"
                          className={css.buttonImage}
                        />
                        <span className={css.buttonDeleteText}>
                          <FormattedMessage id="ShoppingCartTable.delete" />
                        </span>
                      </button>
                      <button
                        className={
                          disableButtonConfirm ? css.buttonConfirmDisabled : css.buttonConfirm
                        }
                        onClick={() => handleSubmit(listing)}
                        disabled={disableButtonConfirm || submitInProgress}
                      >
                        {submitInProgress && idInProgress === listing.idForCart ? (
                          <IconSpinner className={css.spinner} />
                        ) : (
                          <span className={css.buttonConfirmText}>
                            <FormattedMessage id="ShoppingCartTable.confirm" />
                          </span>
                        )}
                      </button>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
      <div className={css.cartTotalContainer}>
        <div className={css.rowTotalContainer}>
          <span className={css.rowText}>
            <FormattedMessage id="ShoppingCartTable.total" />
          </span>
          <div className={css.price}>{`$${total}`}</div>
        </div>
        {/* <div className={css.rowTotalContainer}>
          <span className={css.rowText}>
            <FormattedMessage id="ShoppingCartTable.otherCharges" />
          </span>
          <div className={css.price}>{`$${otherCharges}`}</div>
        </div>
        <div className={css.rowTotalContainer}>
          <span className={css.rowText}>
            <FormattedMessage id="ShoppingCartTable.localTaxes" />
          </span>
          <div className={css.price}>{`$${taxes}`}</div>
        </div>
        <div className={css.rowTotalContainer}>
          <span className={css.rowText}>
            <FormattedMessage id="ShoppingCartTable.grandTotal" />
          </span>
          <div className={css.price}>{`$${total + taxes}`}</div>
        </div> */}
      </div>
    </div>
  );
};

ShoppingCartTable.propTypes = {
  editClick: func,
  deleteClick: func,
  confirmClick: func,
  userCartList: array,
  disableButtonConfirm: bool,
};

export default ShoppingCartTable;
