import React from 'react';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const SectionAllowedGuestsMaybe = props => {
  const { publicData } = props;
  return publicData && publicData.numberOfGuestsFrom && publicData.numberOfGuestsTo ? (
    <div className={css.listingPageSection}>
      <h2 className={css.listingPageSectionTitle}>
        <FormattedMessage id="ListingPage.allowedGuests" />
      </h2>
      <p className={css.listingPageSectionContent}>
        {publicData.numberOfGuestsFrom}-{publicData.numberOfGuestsTo}
      </p>
    </div>
  ) : null;
};

SectionAllowedGuestsMaybe.defaultProps = { className: null, rootClassName: null };

SectionAllowedGuestsMaybe.propTypes = {
  className: string,
  rootClassName: string,
  publicData: shape({
    cancellationPolicy: string,
  }),
};

export default SectionAllowedGuestsMaybe;
