import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingFavouritesForm } from '../../forms';
import {
  ensureCurrentUser,
  ensureOwnListing
} from '../../util/data';
import { NamedLink, AvatarLarge, AvatarMedium } from '../../components';
import addToFavoritesLinkImg from '../../assets/addToFavoritesBtnImg.svg';

import css from './ListingPage.css';

const SectionAvatar = props => {
  const { 
    params,
    title,
    listing,
    onUpdateProfile,
    currentUser,
    user,
    isAuthenticated,
   } = props;

  const { uuid } = listing.id;
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const save = async values => {
    await sleep(1000)
  };

  const userProfile = ensureCurrentUser(currentUser);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const listingFavourite =
    currentListing &&
      userProfile.attributes.profile.publicData &&
      (userProfile.attributes.profile.publicData.listingFavourite !== undefined)
      ? userProfile.attributes.profile.publicData.listingFavourite : [];
  const init = listingFavourite ? listingFavourite.filter(l => l.listingId === id) : [];
  const authorName = currentListing.author.attributes.profile.displayName;
  const priceAmount = currentListing.attributes.price && currentListing.attributes.price.amount;
  const priceCurrency = currentListing.attributes.price && currentListing.attributes.price.currency;
  const listingImages = currentListing.images && currentListing.images.length > 0 ? currentListing.images[0].attributes.variants["landscape-crop"].url : null;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const AddFavouriteButton = isAuthenticatedOrJustHydrated  ? (
    <ListingFavouritesForm
      className={css.checkbox}
      initialValues={{
        [`favourite_${id}`]: init[0] ? init[0][`favourite_${id}`] : false
      }}
      onSubmit={values => {
        save(values);
        let currentListingId = Object.keys(values)[0];
        let currentListingValue = values[`favourite_${id}`];
        currentListingId = currentListingId.replace('favourite_', '');
        const hasCurrentListing = listingFavourite.filter(l => l.listingId === currentListingId).length > 0;


        if (!hasCurrentListing && currentListingValue) {
          onUpdateProfile({
            publicData: {
              listingFavourite: [...listingFavourite, {
                title,
                authorName,
                priceAmount,
                priceCurrency,
                listingImages,
                listingId: id,
                [`favourite_${id}`]: true
              }]
            }
          });
        }
        if (hasCurrentListing && !currentListingValue) {
          const removeCurrentListing = listingFavourite.filter(l => l.listingId !== currentListingId);
          onUpdateProfile({
            publicData: {
              listingFavourite: removeCurrentListing
            }
          });
        }
      }}
      onChange={values => { }}
      listingId={id}
    />
  ) : 
  (
    <NamedLink className={css.favouriteButton + ' ' + css.favouriteImgLink} name="SignupPage">
      <img src={addToFavoritesLinkImg} alt="Add to favorites" />
    </NamedLink>
  );


  return (
    <div className={css.sectionAvatar}>
      <div className={css.AddFavouriteBtn}>
          {AddFavouriteButton}
          <div className={css.AddFavouriteBtnText}>
            <FormattedMessage id="ListingPage.addFavouriteBtn" />
          </div>
      </div>
        <AvatarLarge user={user} className={css.avatarDesktop} disableProfileLink />
        <AvatarMedium user={user} className={css.avatarMobile} disableProfileLink />
    </div>
  );
};

export default SectionAvatar;