import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import {
  isChangeEmailTakenError,
  isChangeEmailWrongPassword,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import { FieldCustomPhoneNumberInput, Form, PrimaryButton, FieldTextInput, NamedLink } from '../../components';

import css from './SmsVerificationFormPhoneUpdate.css';

const SHOW_EMAIL_SENT_TIMEOUT = 2000;

class SmsVerificationFormPhoneUpdateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { showVerificationEmailSentMessage: false };
    this.emailSentTimeoutId = null;
    this.handleResendVerificationEmail = this.handleResendVerificationEmail.bind(this);
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.emailSentTimeoutId);
  }

  handleResendVerificationEmail() {
    this.setState({ showVerificationEmailSentMessage: true });

    this.props.onResendVerificationEmail().then(() => {
      this.emailSentTimeoutId = window.setTimeout(() => {
        this.setState({ showVerificationEmailSentMessage: false });
      }, SHOW_EMAIL_SENT_TIMEOUT);
    });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            saveEmailError,
            savePhoneNumberError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            sendVerificationEmailError,
            sendVerificationEmailInProgress,
            values,
            initialValues,
            phoneChanged
          } = fieldRenderProps;
          const { phoneNumber } = values;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const { profile } = user.attributes;

          // phone
          const protectedData = profile.protectedData || {};
          const currentPhoneNumber = protectedData.phoneNumber;

          // has the phone number changed
          const phoneNumberChanged = currentPhoneNumber !== phoneNumber;
          const smsUnverifiedInfo = (
            <div className={css.smsUnverified}>
              <FormattedMessage
                id="ContactDetailsForm.smsUnverified"
              />
              <NamedLink className={phoneNumberChanged ? css.verifyUnvisible : css.verifyVisible} name="SmsVerificationPage">
                <span className={css.helperLink}>
                  <FormattedMessage id="ContactDetailsForm.verify" />
                </span>
              </NamedLink>
            </div>
          );

          const phoneNumberRequiredMessage = intl.formatMessage({
            id: 'SignupForm.phoneRequired',
          });
          const phoneNumberMinLengthMessage = intl.formatMessage(
            {
              id: 'SignupForm.phoneMinLength',
            },
            {
              minLength: validators.PHONE_MIN_LENGTH,
            }
          );
          const phoneNumberMinLength = validators.minLength(
            phoneNumberMinLengthMessage,
            validators.PHONE_MIN_LENGTH
          );

          const phoneNumberRequired = validators.requiredStringNoTrim(phoneNumberRequiredMessage);
          const phoneNumberValidators = validators.composeValidators(
            phoneNumberRequired,
            phoneNumberMinLength,
          );

          let genericError = null;

          if (savePhoneNumberError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="ContactDetailsForm.genericFailure" />
              </span>
            );
          } else if (savePhoneNumberError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="ContactDetailsForm.genericPhoneNumberFailure" />
              </span>
            );
          }

          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >

              <div className={css.phoneNumber}>
                <span className={css.phoneNumberLabel}>
                  <FormattedMessage id="SmsVerificationPage.phoneNumber" />
                </span>
                <FieldCustomPhoneNumberInput
                  id="phoneNumber"
                  name="phoneNumber"
                  validate={phoneNumberValidators}
                />
              </div>

              {phoneChanged && <span className={css.phoneChangedText}>
                  <FormattedMessage id="SmsVerificationPage.successfullyUpdated" />
                </span>
              }

              {values.phoneNumber !== initialValues.phoneNumber ? (
                <div className={css.bottomWrapper}>
                  {genericError}
                  <PrimaryButton
                    type="submit"
                    inProgress={inProgress}
                    className={css.submitBtn}
                  // ready={pristineSinceLastSubmit}
                  // disabled={submitDisabled}
                  >
                    <FormattedMessage id="SmsVerificationPage.updatePhone" />
                  </PrimaryButton>
                </div>
              )  : null}
            </Form>
          );
        }}
      />
    );
  }
}

SmsVerificationFormPhoneUpdateComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  savePhoneNumberError: null,
  inProgress: false,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
  email: null,
  phoneNumber: null,
};

const { bool, func, string } = PropTypes;

SmsVerificationFormPhoneUpdateComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  onResendVerificationEmail: func.isRequired,
  ready: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool,
};

const SmsVerificationFormPhoneUpdate = compose(injectIntl)(SmsVerificationFormPhoneUpdateComponent);

SmsVerificationFormPhoneUpdate.displayName = 'SmsVerificationFormPhoneUpdate';

export default SmsVerificationFormPhoneUpdate;
