import React from 'react';
import { string, bool } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.css';

const SectionHero = props => {
  const { rootClassName, className, isAuthenticated } = props;

  const classes = classNames(rootClassName || css.root, className);

  const sectionHeroButton = isAuthenticated ? (
      <NamedLink name="ProfileSettingsPage" className={css.heroButton}>
        <FormattedMessage id="SectionHero.viewProfileButton" />
      </NamedLink>
    ) : (
      <NamedLink name="SignupPage" className={css.heroButton}>
        <FormattedMessage id="SectionHero.browseButton" />
      </NamedLink>
    );

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        {sectionHeroButton}
        <div className={css.heroActionButtonsWrapper}>
          <NamedLink
            name="SearchPage"
            to={{
              search:
              'bounds=81.77060342%2C96.19903564%2C-81.46941277%2C-167.91778564&mapSearch=true&pub_category=friend',
            }}
            className={css.heroButtonAction}
          >
            <FormattedMessage id="SectionHero.findFriendButton" />
          </NamedLink>
          <NamedLink
            name="SearchPage"
            to={{
              search:
                'bounds=81.77060342%2C96.19903564%2C-81.46941277%2C-167.91778564&mapSearch=true&pub_category=online',
            }}
            className={css.heroButtonAction}
          >
            <FormattedMessage id="SectionHero.findOnlineButton" />
          </NamedLink>
          <NamedLink
            name="SearchPage"
            to={{
              search:
              'bounds=81.77060342%2C96.19903564%2C-81.46941277%2C-167.91778564&mapSearch=true&pub_category=food',
            }}
            className={css.heroButtonAction}
          >
            <FormattedMessage id="SectionHero.findFoodButton" />
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
};

export default SectionHero;
