import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  AvatarLarge,
  NamedLink,
  ListingCard,
  Reviews,
  ButtonTabNavHorizontal,
  ReviewRating,
  NamedRedirect,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { loadData } from './ProfilePage.duck';
import config from '../../config';

import { getAdminData } from '../../ducks/AdminData.duck';

import css from './ProfilePage.css';

const { UUID } = sdkTypes;
const MAX_MOBILE_SCREEN_WIDTH = 768;

const findEqualObjectsReview = (ratings, review) => {
  const equalObjects = [];

  ratings.forEach(elementOfRatings => {
    review.forEach(elementOfReview => {
      if (
        elementOfRatings.ratingCriteries &&
        elementOfRatings.ratingCriteries.reviewId === elementOfReview.id.uuid
      ) {
        equalObjects.push(elementOfReview);
      }
    });
  });

  return equalObjects;
};

const findEqualObjectsRaiting = (ratings, review) => {
  const equalObjects = [];

  ratings.forEach(elementOfRatings => {
    review.forEach(elementOfReview => {
      if (
        elementOfRatings.ratingCriteries &&
        elementOfRatings.ratingCriteries.reviewId === elementOfReview.id.uuid
      ) {
        equalObjects.push(elementOfRatings);
      }
    });
  });

  return equalObjects;
};

export class ProfilePageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // keep track of which reviews tab to show in desktop viewport
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
      itemsToShow: 6,
      expanded: false,
    };

    this.showOfProviderReviews = this.showOfProviderReviews.bind(this);
    this.showOfCustomerReviews = this.showOfCustomerReviews.bind(this);

    this._mounted = false;

  }

  componentDidMount() {
    this._mounted = true;
    this.props.onGetAdminData();
  }

  showOfProviderReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    });
  }

  showOfCustomerReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_CUSTOMER,
    });
  }
  countAverageCriteriaRating = (slug, ratings) => {
    const filteredRating = ratings ? ratings.filter(rating => rating.ratingCriteries[slug]) : [];
    const count = filteredRating.reduce(
      (a, e) => a + Number(e.ratingCriteries[slug] ? e.ratingCriteries[slug] : 0),
      0
    );
    const length = filteredRating.length;
    return Math.round(count / length);
  };

  render() {
    const {
      scrollingDisabled,
      currentUser,
      user,
      userShowError,
      queryListingsError,
      listings,
      reviews,
      queryReviewsError,
      viewport,
      intl,
      onGetAdminData,
      adminData,
    } = this.props;

    const { itemsToShow, expanded } = this.state;

    const showMore = () => {
      if (itemsToShow === 6) {
        this.setState({ itemsToShow: listings.length, expanded: true });
      } else {
        this.setState({ itemsToShow: 6, expanded: false });
      }
    };

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const videoWidth = isMobileLayout ? viewport.width - 52 : 570;
    const videoHeight = isMobileLayout ? viewport.width - 168 : 420;

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const profileUser = ensureUser(user);
    const isCurrentUser =
      ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
    const displayName = profileUser.attributes.profile.displayName;
    const bio = profileUser.attributes.profile.bio;
    const hasBio = !!bio;
    const hasListings = listings.length > 0;
    let ratings = profileUser.attributes.profile.publicData
      ? profileUser.attributes.profile.publicData.reviewsWithCriteries
      : [];
    const isUserPaymentVerified =
      profileUser.attributes.profile.publicData &&
      profileUser.attributes.profile.publicData.verifiedUserPayment !== undefined
        ? profileUser.attributes.profile.publicData.verifiedUserPayment
        : false;
    const videoUrl =
      profileUser.attributes.profile.publicData &&
      profileUser.attributes.profile.publicData.videoUrl;
    const approvedVideo =
      profileUser.attributes.profile.publicData &&
      profileUser.attributes.profile.publicData.approvedVideo;
    const videoLocal =
      profileUser.attributes.profile.publicData &&
      profileUser.attributes.profile.publicData.videoLocal;

    const filterReviews =
      ratings && ratings.length > 0 && reviews.length > 0
        ? findEqualObjectsReview(ratings, reviews).reverse()
        : reviews;
    const filterRaitings =
      ratings && ratings.length > 0 && reviews.length > 0
        ? findEqualObjectsRaiting(ratings, reviews).reverse()
        : [];

    let reviewsTotalRating = filterRaitings.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.reviewRating;
    }, 0);
    const reviewsAverageRating = Math.round(reviewsTotalRating / filterRaitings.length);

    const editLinkMobile = isCurrentUser ? (
      <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkMobile" />
      </NamedLink>
    ) : null;
    const editLinkDesktop = isCurrentUser ? (
      <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
      </NamedLink>
    ) : null;

    const characteristicsList = (
      <ul className={css.characteristicsList}>
        <li className={css.characteristicsListItem}>
          <div className={css.characteristicsTitle}>
            <FormattedMessage id="ProfilePage.personFriendliness" />
          </div>
          <ReviewRating
            rating={this.countAverageCriteriaRating('Friendliness', filterRaitings)}
            className={css.characteristicsRating}
            reviewStarClassName={css.characteristicsRatingStar}
            reviewStarFilledAltClassName={css.characteristicsRatingStarAltColor}
          />
        </li>
        <li className={css.characteristicsListItem}>
          <div className={css.characteristicsTitle}>
            <FormattedMessage id="ProfilePage.personPunctuality" />
          </div>
          <ReviewRating
            rating={this.countAverageCriteriaRating('Punctuality', filterRaitings)}
            className={css.characteristicsRating}
            reviewStarClassName={css.characteristicsRatingStar}
            reviewStarFilledAltClassName={css.characteristicsRatingStarAltColor}
          />
        </li>
        <li className={css.characteristicsListItem}>
          <div className={css.characteristicsTitle}>
            <FormattedMessage id="ProfilePage.personSafety" />
          </div>
          <ReviewRating
            rating={this.countAverageCriteriaRating('Safety', filterRaitings)}
            className={css.characteristicsRating}
            reviewStarClassName={css.characteristicsRatingStar}
            reviewStarFilledAltClassName={css.characteristicsRatingStarAltColor}
          />
        </li>
        <li className={css.characteristicsListItem}>
          <div className={css.characteristicsTitle}>
            <FormattedMessage id="ProfilePage.personRecommendations" />
          </div>
          <ReviewRating
            rating={this.countAverageCriteriaRating('Recommendations', filterRaitings)}
            className={css.characteristicsRating}
            reviewStarClassName={css.characteristicsRatingStar}
            reviewStarFilledAltClassName={css.characteristicsRatingStarAltColor}
          />
        </li>
        <li className={css.characteristicsListItem}>
          <div className={css.characteristicsTitle}>
            <FormattedMessage id="ProfilePage.personExpectations" />
          </div>
          <ReviewRating
            rating={this.countAverageCriteriaRating('Expectations', filterRaitings)}
            className={css.characteristicsRating}
            reviewStarClassName={css.characteristicsRatingStar}
            reviewStarFilledAltClassName={css.characteristicsRatingStarAltColor}
          />
        </li>
        <li className={css.characteristicsListItem}>
          <div className={css.characteristicsTitle}>
            <FormattedMessage id="ProfilePage.personСleanliness" />
          </div>
          <ReviewRating
            rating={this.countAverageCriteriaRating('Сleanliness', filterRaitings)}
            className={css.characteristicsRating}
            reviewStarClassName={css.characteristicsRatingStar}
            reviewStarFilledAltClassName={css.characteristicsRatingStarAltColor}
          />
        </li>
      </ul>
    );

    const asideContent = (
      <div className={css.asideContent}>
        <div className={css.avatarWrapper}>
          <div className={css.avatarBlock}>
            <AvatarLarge className={css.avatar} user={user} disableProfileLink />
            {editLinkMobile}
          </div>
          <h1 className={css.mobileHeading}>
            {displayName ? (
              <FormattedMessage id="ProfilePage.mobileHeading" values={{ name: displayName }} />
            ) : null}
          </h1>
        </div>
        {editLinkDesktop}
        {characteristicsList}
      </div>
    );

    const listingsContainerClasses = classNames(css.listingsContainer, {
      [css.withBioMissingAbove]: !hasBio,
    });

    const reviewsError = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
      </p>
    );

    const reviewsOfProvider = filterReviews.filter(
      r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER
    );

    const reviewsOfCustomer = filterReviews.filter(
      r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER
    );

    const mobileReviews = (
      <div className={css.mobileReviews}>
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfProviderTitle"
            values={{ count: reviewsOfProvider.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfProvider} ratings={filterRaitings} />
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfCustomerTitle"
            values={{ count: reviewsOfCustomer.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfCustomer} ratings={filterRaitings} />
      </div>
    );

    const desktopReviewTabs = [
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfProviderTitle"
              values={{ count: reviewsOfProvider.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER,
        onClick: this.showOfProviderReviews,
      },
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfCustomerTitle"
              values={{ count: reviewsOfCustomer.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
        onClick: this.showOfCustomerReviews,
      },
    ];

    const desktopReviews = (
      <div className={css.desktopReviews}>
        <ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs} />

        {queryReviewsError ? reviewsError : null}

        {this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER ? (
          <Reviews reviews={reviewsOfProvider} ratings={filterRaitings} />
        ) : (
          <Reviews reviews={reviewsOfCustomer} ratings={filterRaitings} />
        )}
      </div>
    );

    const showMorelink =
      listings.length > 6 ? (
        <a className={css.showMorelink} onClick={showMore}>
          {expanded ? <span>See less</span> : <span>See more</span>}
        </a>
      ) : null;

    const mainContent = (
      <>
        <h1 className={css.desktopHeading}>
          <FormattedMessage id="ProfilePage.desktopHeading" values={{ name: displayName }} />
        </h1>
        <div className={css.overallRatingWrapper}>
          <ReviewRating
            rating={reviewsAverageRating}
            className={css.overallRating}
            reviewStarClassName={css.overallRatingStar}
          />
          <p className={css.overallRatingCounter}>
            <FormattedMessage
              id="ProfilePage.overallRatingReviews"
              values={{ count: reviewsOfProvider.length + reviewsOfCustomer.length }}
            />
          </p>
        </div>
        {isUserPaymentVerified ? (
          <span className={css.verifiedUserBadge}>
            <FormattedMessage id="ProfilePage.verifiedUserText" />
          </span>
        ) : null}
        {hasBio ? <p className={css.bio}>{bio}</p> : null}
        {approvedVideo ? (
          videoLocal ? (
            <div className={css.videoContainer}>
              <ReactPlayer
                url={[{ src: videoUrl, type: 'video/mp4' }]}
                width={videoWidth}
                height={videoHeight}
                controls={true}
                // light={true}
              />
            </div>
          ) : (
            <div className={css.videoContainer}>
              <ReactPlayer
                url={videoUrl}
                width={videoWidth}
                height={videoHeight}
                controls={true}
                // light={true}
              />
            </div>
          )
        ) : null}
        {hasListings ? (
          <div className={listingsContainerClasses}>
            <h2 className={css.listingsTitle}>
              <FormattedMessage id="ProfilePage.listingsTitle" />
            </h2>
            <ul className={css.listings}>
              {listings.slice(0, itemsToShow).map(l => (
                <li className={css.listing} key={l.id.uuid}>
                  <ListingCard listing={l} favoriteBtn={false} />
                </li>
              ))}
            </ul>
            {showMorelink}
          </div>
        ) : null}
        {isMobileLayout ? mobileReviews : desktopReviews}
      </>
    );

    let content;

    if (userShowError && userShowError.status === 404) {
      return <NotFoundPage />;
    } else if (userShowError || queryListingsError) {
      content = (
        <p className={css.error}>
          <FormattedMessage id="ProfilePage.loadingDataFailed" />
        </p>
      );
    } else {
      content = mainContent;
    }

    const schemaTitle = intl.formatMessage(
      {
        id: 'ProfilePage.schemaTitle',
      },
      {
        name: displayName,
        siteTitle: config.siteTitle,
      }
    );

    const adminPublicData = adminData && adminData.attributes && adminData.attributes.profile.publicData;
    const publicMarketplace = adminPublicData && adminPublicData.publicMarketplace;

    const phoneVerified = currentUser && currentUser.attributes.profile.protectedData.phoneVerified;

    if( this._mounted && phoneVerified === false ) {
      return  <NamedRedirect name="SmsVerificationPage" />
    }

    if( this._mounted && publicMarketplace === false && !currentUser ) {
      return  <NamedRedirect name="SignupPage" />
    }

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ProfilePage',
          name: schemaTitle,
        }}
      >
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ProfilePage" />
        </LayoutWrapperTopbar>

        <div className={css.profilePageWrapper}>
          <LayoutWrapperMain className={css.centralContentWrapper}>{content}</LayoutWrapperMain>
          <LayoutWrapperSideNav className={css.aside}>{asideContent}</LayoutWrapperSideNav>
        </div>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
  }
}

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { adminData, getAdminDataProgress, getAdminDataError } = state.AdminData;
  const {
    userId,
    userShowError,
    queryListingsError,
    userListingRefs,
    reviews,
    queryReviewsError,
  } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  const listings = getMarketplaceEntities(state, userListingRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    queryListingsError,
    listings,
    reviews,
    queryReviewsError,
    adminData,
    getAdminDataProgress,
    getAdminDataError,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetAdminData: () => dispatch(getAdminData()),
});

const ProfilePage = compose(
  connect(
    mapStateToProps, 
    mapDispatchToProps
  ),
  withViewport,
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
  const id = new UUID(params.id);
  return loadData(id);
};

export default ProfilePage;
