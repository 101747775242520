import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { TopbarContainer, NotFoundPage } from '../../containers';

import { loadData } from './ReviewsPage.duck';
import css from './ReviewsPage.css';

import {
  Footer,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  UserNav,
  LayoutSideNavigation,
  ProfileReviews,
  ButtonTabNavHorizontal,
} from '../../components';

import config from '../../config';

const { UUID } = sdkTypes;
const MAX_MOBILE_SCREEN_WIDTH = 768;

const findEqualObjectsReview = (ratings, review) => {
  const equalObjects = [];

  ratings.forEach(elementOfRatings => {
    review.forEach(elementOfReview => {
      if (
        elementOfRatings.ratingCriteries &&
        elementOfRatings.ratingCriteries.reviewId === elementOfReview.id.uuid
      ) {
        equalObjects.push(elementOfReview);
      }
    });
  });

  return equalObjects;
};

const findEqualObjectsRaiting = (ratings, review) => {
  const equalObjects = [];

  ratings.forEach(elementOfRatings => {
    review.forEach(elementOfReview => {
      if (
        elementOfRatings.ratingCriteries &&
        elementOfRatings.ratingCriteries.reviewId === elementOfReview.id.uuid
      ) {
        equalObjects.push(elementOfRatings);
      }
    });
  });

  return equalObjects;
};

export const ReviewsPageComponent = props => {
  const {
    scrollingDisabled,
    currentUser,
    user,
    userShowError,
    queryListingsError,
    listings,
    reviews,
    queryReviewsError,
    viewport,
    intl,
  } = props;

  const hasListings = listings.length > 0;

  const [showReviewsType, setShowReviewsType] = useState(
    hasListings ? REVIEW_TYPE_OF_PROVIDER : REVIEW_TYPE_OF_CUSTOMER
  );

  const profileUser = ensureUser(user);
  const displayName = profileUser.attributes.profile.displayName;
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  let ratings = profileUser.attributes.profile.publicData
    ? profileUser.attributes.profile.publicData.reviewRating
    : [];

  const reviewsWithCriteries =
    profileUser.attributes.profile.publicData &&
    profileUser.attributes.profile.publicData.reviewsWithCriteries
      ? profileUser.attributes.profile.publicData.reviewsWithCriteries
      : [];

  const showOfProviderReviews = () => {
    setShowReviewsType(REVIEW_TYPE_OF_PROVIDER);
  };

  const showOfCustomerReviews = () => {
    setShowReviewsType(REVIEW_TYPE_OF_CUSTOMER);
  };

  const schemaTitle = intl.formatMessage(
    {
      id: 'ReviewsPage.schemaTitle',
    },
    {
      name: displayName,
      siteTitle: config.siteTitle,
    }
  );

  const reviewsError = (
    <p className={css.error}>
      <FormattedMessage id="ReviewsPage.loadingReviewsFailed" />
    </p>
  );

  const filterReviews = findEqualObjectsReview(reviewsWithCriteries, reviews).reverse();
  const filterRaitings = findEqualObjectsRaiting(reviewsWithCriteries, reviews).reverse();

  const reviewsOfProvider = filterReviews.filter(
    r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER
  );
  const reviewsOfCustomer = filterReviews.filter(
    r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER
  );

  const mobileReviews = (
    <div className={css.mobileReviews}>
      {hasListings ? (
        <>
          <h2 className={css.mobileReviewsTitle}>
            <FormattedMessage
              id="ReviewsPage.reviewsOfProviderTitle"
              values={{ count: reviewsOfProvider.length }}
            />
          </h2>
          {queryReviewsError ? reviewsError : null}
          <ProfileReviews reviews={reviewsOfProvider} reviewsWithCriteries={filterRaitings} />
        </>
      ) : null}
      <h2 className={css.mobileReviewsTitle}>
        <FormattedMessage
          id="ReviewsPage.reviewsOfCustomerTitle"
          values={{ count: reviewsOfCustomer.length }}
        />
      </h2>
      {queryReviewsError ? reviewsError : null}
      <ProfileReviews reviews={reviewsOfCustomer} reviewsWithCriteries={filterRaitings} />
    </div>
  );

  const desktopReviewTabs = [
    {
      text: (
        <h3 className={css.desktopReviewsTitle}>
          <FormattedMessage
            id="ReviewsPage.reviewsOfProviderTitle"
            values={{ count: reviewsOfProvider.length }}
          />
        </h3>
      ),
      selected: showReviewsType === REVIEW_TYPE_OF_PROVIDER,
      onClick: showOfProviderReviews,
    },
    {
      text: (
        <h3 className={css.desktopReviewsTitle}>
          <FormattedMessage
            id="ReviewsPage.reviewsOfCustomerTitle"
            values={{ count: reviewsOfCustomer.length }}
          />
        </h3>
      ),
      selected: showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
      onClick: showOfCustomerReviews,
    },
  ];

  const desktopReviewTabsCustomer = [
    {
      text: (
        <h3 className={css.desktopReviewsTitle}>
          <FormattedMessage
            id="ReviewsPage.reviewsOfCustomerTitle"
            values={{ count: reviewsOfCustomer.length }}
          />
        </h3>
      ),
      selected: true,
      onClick: showOfCustomerReviews,
    },
  ];

  const desktopReviews = (
    <div className={css.desktopReviews}>
      <ButtonTabNavHorizontal
        className={css.desktopReviewsTabNav}
        tabs={hasListings ? desktopReviewTabs : desktopReviewTabsCustomer}
      />

      {queryReviewsError ? reviewsError : null}
      {showReviewsType === REVIEW_TYPE_OF_PROVIDER ? (
        <ProfileReviews reviews={reviewsOfProvider} reviewsWithCriteries={filterRaitings} />
      ) : (
        <ProfileReviews reviews={reviewsOfCustomer} reviewsWithCriteries={filterRaitings} />
      )}
    </div>
  );

  const mainContent = (
    <>
      <h1 className={css.desktopHeading}>
        <FormattedMessage id="ReviewsPage.title" />
      </h1>
      {isMobileLayout ? mobileReviews : desktopReviews}
    </>
  );

  let content;

  if (userShowError && userShowError.status === 404) {
    return <NotFoundPage />;
  } else if (userShowError || queryListingsError) {
    content = (
      <p className={css.error}>
        <FormattedMessage id="ReviewsPage.loadingDataFailed" />
      </p>
    );
  } else {
    content = mainContent;
  }

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ReviewsPage',
        name: schemaTitle,
      }}
    >
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ReviewsPage" />
          {currentUser && <UserNav selectedPageName="ReviewsPage" currentUser={currentUser} />}
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.centralContentWrapper}>
          <div className={css.profilePageWrapper}>{content}</div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ReviewsPageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ReviewsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    userId,
    userShowError,
    queryListingsError,
    userListingRefs,
    reviews,
    queryReviewsError,
  } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  const listings = getMarketplaceEntities(state, userListingRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    queryListingsError,
    listings,
    reviews,
    queryReviewsError,
  };
};

const ReviewsPage = compose(
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(ReviewsPageComponent);

ReviewsPage.loadData = params => {
  const id = new UUID(params.id);
  return loadData(id);
};

export default ReviewsPage;
