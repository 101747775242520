import React from 'react';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const SectionFeaturesMaybe = props => {
  const { publicData } = props;

  const featureWordsText = publicData.featureWords ? publicData.featureWords.trim() : '';

  return publicData && publicData.featureWords && featureWordsText.length > 0 ? (
    <div className={css.listingPageSection}>
      <h2 className={css.listingPageSectionTitle}>
        <FormattedMessage id="ListingPage.keywordsTitle" />
      </h2>
      <p className={css.listingPageSectionContent}>{featureWordsText}</p>
    </div>
  ) : null;
};

SectionFeaturesMaybe.defaultProps = { className: null, rootClassName: null };

SectionFeaturesMaybe.propTypes = {
  className: string,
  rootClassName: string,
  publicData: shape({
    featureWords: string,
  }),
};

export default SectionFeaturesMaybe;
