import React from 'react';
import { func, shape } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {Form, FieldCheckboxLikeButton} from '../../components';
import css from './ListingFavouritesForm.css';
import AutoSave from './AutoSave';

export const ListingFavouritesFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        handleSubmit,
        onChange,
        listingId,
      } = fieldRenderProps;

      const classes = classNames(css.root, className);
      return (
        <Form onSubmit={handleSubmit} className={classes} onChange={onChange}>
          <AutoSave debounce={1000} save={handleSubmit} />
          <FieldCheckboxLikeButton
            id={`favourite_${listingId}`}
            name={`favourite_${listingId}`}
          />
        </Form>
      );
    }}
  />
);

ListingFavouritesFormComponent.defaultProps = { fetchErrors: null };

ListingFavouritesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(ListingFavouritesFormComponent);
