import React, { useState } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.css';

const Review = props => {
  const { review, intl, raiting, content } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} />
      <div className={css.reviewWrapper}>
        <ReviewRating
          rating={raiting}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />
        <p className={css.reviewContent}>{content}</p>
        <p className={css.reviewInfo}>
          <span>
            <UserDisplayName user={review.author} intl={intl} />
            <span className={css.separator}>•</span>
            {dateString}
          </span>
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={raiting}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const [itemsToShow, setItemsToShow] = useState(3);
  const [expanded, setExpanded] = useState(false);

  const { className, rootClassName, reviews, intl, ratings } = props;
  const classes = classNames(rootClassName || css.root, className);

  const showMore = () => {
    if (itemsToShow === 3) {
      setItemsToShow(ratings.length);
      setExpanded(true);
    } else {
      setItemsToShow(3);
      setExpanded(false);
    }
  };

  const showMorelink =
    reviews && reviews.length > 3 ? (
      <a className={css.showMorelink} onClick={showMore}>
        {expanded ? <span>See less</span> : <span>See more</span>}
      </a>
    ) : null;

  return (
    <>
      <ul className={classes}>
        {reviews.slice(0, itemsToShow).map(r => {
          const id = r.id.uuid;
          const criteriesReview = ratings && ratings.filter(
            rev => rev.ratingCriteries.reviewId === id
          )[0];

          const raiting = criteriesReview ? criteriesReview.reviewRating : null;
          const content = criteriesReview ? criteriesReview.reviewContent : null;

          return (
            <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
              <Review review={r} intl={intl} raiting={raiting} content={content} />
            </li>
          );
        })}
      </ul>
      {showMorelink}
    </>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
