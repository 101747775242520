import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import css from './BookingBreakdown.css';
import { TRANSITION_CANCEL_CUSTOMER, TRANSITION_DECLINE_CUSTOMER } from '../../util/transaction';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};

const lineItemsTotalCustomer = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount * -1, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Returns non-commission, reversal line items
 */
const nonCommissionReversalLineItems = transaction => {
  return transaction.attributes.lineItems.filter(item => !isCommission(item) && item.reversal);
};

const LineItemRefundMaybe = props => {
  const { transaction, intl, isCustomer } = props;

  // all non-commission, reversal line items
  const refundLineItems = nonCommissionReversalLineItems(transaction);

  const refund = lineItemsTotal(refundLineItems);
  const refundCustomer = lineItemsTotalCustomer(refundLineItems);

  let formattedRefund = refundLineItems.length > 0 ? formatMoney(intl, refund) : null;
  const lastTransition = transaction.attributes.lastTransition;
  let customRefund =
    transaction &&
    transaction.attributes.protectedData &&
    transaction.attributes.protectedData.refound
      ? transaction.attributes.protectedData.refound.refound
      : null;
  if (lastTransition === TRANSITION_CANCEL_CUSTOMER && customRefund) {
    customRefund = isCustomer
      ? new Money(customRefund, config.currency)
      : new Money(customRefund * -1, config.currency);
    formattedRefund = formatMoney(intl, customRefund);
  }
  if (lastTransition === TRANSITION_DECLINE_CUSTOMER && isCustomer) {
    formattedRefund = formatMoney(intl, refundCustomer);
  }

  const refundMessageByRole = isCustomer ? (
    <FormattedMessage id="BookingBreakdown.refundCustomer" />
  ) : (
    <FormattedMessage id="BookingBreakdown.refundProvider" />
  );

  const isCancelationCustomer = lastTransition === TRANSITION_CANCEL_CUSTOMER && isCustomer;

  const itemLabelClass = isCancelationCustomer ? css.totalLabelCustomer : css.itemLabel;

  const itemValueClass = isCancelationCustomer ? css.totalPrice : css.itemValue;

  return formattedRefund ? (
    <div className={css.lineItem}>
      <span className={itemLabelClass}>{refundMessageByRole}</span>
      <span className={itemValueClass}>{formattedRefund}</span>
    </div>
  ) : null;
};

LineItemRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemRefundMaybe;
