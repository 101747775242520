import React from 'react';
import { FieldSelect } from '../../components';
import { required } from '../../util/validators';

import css from './EditListingPricingForm.css';

const CustomDurationSelectFieldMaybe = props => {
  const { name, id, duration, intl } = props;
  const placeholder = intl.formatMessage({
    id: 'EditListingPricingForm.timeOfActivityPlaceholder',
  });
  const timeOfActivityRequired = required(
    intl.formatMessage({
    id: 'EditListingPricingForm.timeOfActivityRequired',
    })
  );

  return duration ? (
    <FieldSelect className={css.duration} name={name} id={id} validate={timeOfActivityRequired}>
        <option key='placeholder' value=''>
          {placeholder}
        </option>
      {duration.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomDurationSelectFieldMaybe;
