import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { updateProfile } from "../ProfileSettingsPage/ProfileSettingsPage.duck";
import { TopbarContainer } from '../../containers';
import {
  Page,
  SectionFavorite,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect
} from '../../components';
import config from '../../config';
import { getAdminData } from '../../ducks/AdminData.duck';

import css from './FavoritesPage.css';

export const FavoritesPageComponent = props => {
  const {
    currentUser,
    onUpdateProfile,
    intl,
    scrollingDisabled,
    adminData,
    onGetAdminData
  } = props;

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    onGetAdminData();
  }, []);
  
  const adminPublicData = adminData && adminData.attributes && adminData.attributes.profile.publicData;
  const publicMarketplace = adminPublicData && adminPublicData.publicMarketplace;
  
  const phoneVerified = currentUser && currentUser.attributes.profile.protectedData.phoneVerified;
    
  if( isMounted && phoneVerified === false ) {
    return  <NamedRedirect name="SmsVerificationPage" />
  }
  
  if( isMounted && publicMarketplace === false && !currentUser ) {
    return  <NamedRedirect name="SignupPage" />
  }

  const title = intl.formatMessage({ id: 'FavoritesPage.title' });
  
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
           <TopbarContainer
            currentPage="FavoritesPage"
          />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.title}>
            <FormattedMessage id="FavoritesPage.heading" />
          </h1>

          <SectionFavorite
            currentUser={currentUser}
            onUpdateProfile={onUpdateProfile}
          />
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>

      </LayoutSingleColumn>
    </Page>
  );
};

FavoritesPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

FavoritesPageComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const { adminData, getAdminDataProgress, getAdminDataError } = state.AdminData;

  return {
    isAuthenticated,
    currentUser,
    adminData,
    getAdminDataProgress,
    getAdminDataError,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onGetAdminData: () => dispatch(getAdminData()),
});

const FavoritesPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(FavoritesPageComponent);

export default FavoritesPage;