import React, { useState, useEffect } from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  txIsCanceled,
  txIsDelivered,
  txIsDeclined,
  TRANSITION_CANCEL_CUSTOMER,
  TRANSITION_DECLINE_CUSTOMER,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import { cancellationTransaction } from '../../containers/TransactionPage/TransactionPage.duck';
import { prepareText } from '../../util/data';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, IconSpinner } from '../../components';
import css from './BookingBreakdown.css';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const ACCTEPED_NAME = 'transition/accept';

const LineItemUnitPriceComponent = props => {
  const {
    transaction,
    isProvider,
    isCustomer,
    intl,
    sendTransactionInProgress,
    sendTransactionSuccess,
    sendTransactionError,
  } = props;

  const [isModalOpen, setModalOpen] = useState(false);
  const startDate = transaction.booking.attributes.start;
  const showCancellationButton = new Date() < startDate;
  const listingType = transaction.listing
    ? transaction.listing.attributes.publicData.cancellationPolicy
    : 'medium';
  const cancellationButton =
    showCancellationButton &&
    !isProvider &&
    transaction.attributes.lastTransition === ACCTEPED_NAME && (transaction.listing.attributes.publicData.cancellationPolicy !== 'non_refundable') ? (
      <button className={css.cancellationButton} onClick={() => setModalOpen(true)}>
        <FormattedMessage id="BookingBreakdown.cancelButton" />
      </button>
    ) : (
      ''
    );

  useEffect(() => {
    sendTransactionSuccess && setModalOpen(false);
  }, [sendTransactionSuccess]);

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }
  const isCancelationProvider = transaction.attributes.lastTransition === TRANSITION_CANCEL_CUSTOMER && isProvider
  const isCancelationCustomer = transaction.attributes.lastTransition === TRANSITION_CANCEL_CUSTOMER && !isProvider
  const isDeclinedCustomer = transaction.attributes.lastTransition === TRANSITION_DECLINE_CUSTOMER && isCustomer;

  const BookingBreakdownTotal =
    isCancelationProvider || isCancelationCustomer ? (
      <FormattedMessage id="BookingBreakdown.totalProvider" />
    ) : isDeclinedCustomer ? (
      <FormattedMessage id="BookingBreakdown.totalCharges" />
    ) : (
      <FormattedMessage id="BookingBreakdown.total" />
    );
  
  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
     BookingBreakdownTotal
  );

  let totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const customRefund =
    transaction &&
    transaction.attributes.protectedData &&
    transaction.attributes.protectedData.refound
      ? transaction.attributes.protectedData.refound.refound
      : null;

  if (customRefund && transaction.attributes.lastTransition === TRANSITION_CANCEL_CUSTOMER) {
    if (totalPrice.amount - customRefund >= 0) {
      totalPrice = totalPrice.amount - customRefund;
      if (isProvider) {
        const refound = transaction.attributes.protectedData.refound.refound;
        const stripe_fee = transaction.attributes.protectedData.refound.stripe_fee;
        totalPrice = transaction.attributes.payinTotal.amount - refound - stripe_fee;
      }
    } else {
      totalPrice = 0;
    }
    totalPrice = new Money(totalPrice, config.currency);
  }
  const formattedTotalPrice = formatMoney(intl, totalPrice);
  const totalLabelClass = isCancelationCustomer ? (
    css.itemValueCustomer
  ) : css.totalLabelCustomer;

  const totalPriceClass = isCancelationCustomer ? (
    css.itemValue
  ) : css.totalPrice;
  const totalLabelTitle =  isCancelationProvider ? (
    <FormattedMessage id="BookingBreakdown.providerCancellationCredit" />
  ) : totalLabel;

  return (
    <>
      { isCancelationCustomer ? (
        null
      ) : <hr className={css.totalDivider} />}

      <div className={css.lineItemTotal}>
        <div className={totalLabelClass}>{totalLabelTitle}</div>
        <div className={totalPriceClass}>{formattedTotalPrice}</div>
        { !isCancelationCustomer ? (
        null
        ) : <hr className={css.totalDivider} />}

        {cancellationButton}
        <Modal
          id="EditAvailabilityPlan"
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onManageDisableScrolling={() => null}
          containerClassName={css.modalContainer}
        >
          <div className={css.cancellationModal}>
            {sendTransactionInProgress ? (
              <>
                <p>
                  <FormattedMessage id="BookingBreakdown.cancellationProgress" />
                </p>
                <div>
                  <IconSpinner className={css.spinner} />
                </div>
              </>
            ) : (
              <>
                {sendTransactionError ? (
                  <p>
                    <FormattedMessage id="BookingBreakdown.ErrorCancellation" />
                  </p>
                ) : (
                  <p>{prepareText(listingType, startDate)}</p>
                )}
                <div className={css.cancellationModalButtonHolder}>
                  <button
                    className={css.cancellationModalButton}
                    onClick={() => {
                      props.onCancellationTransaction(transaction);
                    }}
                  >
                    <FormattedMessage id="BookingBreakdown.yes" />
                  </button>
                  <button
                    className={classNames(
                      css.cancellationModalButton,
                      css.cancellationModalButtonNo
                    )}
                    onClick={() => setModalOpen(false)}
                  >
                    <FormattedMessage id="BookingBreakdown.no" />
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

LineItemUnitPriceComponent.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    sendTransactionInProgress,
    sendTransactionSuccess,
    sendTransactionError,
  } = state.TransactionPage;

  return {
    sendTransactionInProgress,
    sendTransactionSuccess,
    sendTransactionError,
  };
};

const mapDispatchToProps = dispatch => ({
  onCancellationTransaction: transaction => dispatch(cancellationTransaction(transaction)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LineItemUnitPrice = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LineItemUnitPriceComponent);

export default LineItemUnitPrice;
