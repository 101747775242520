import React from 'react';
import { FieldSelect } from '../../components';
import { required } from '../../util/validators';

import css from './EditListingPoliciesForm.css';

const CancellationPolicySelectFieldMaybe = props => {
  const { name, id, cancellationPolicy, intl } = props;

  const timeOfActivityRequired = required(
    intl.formatMessage({
    id: 'EditListingPricingForm.timeOfActivityRequired',
    })
  );

  return cancellationPolicy ? (
    <FieldSelect
      className={css.cancellationPolicy}
      name={name}
      id={id}
      validate={timeOfActivityRequired}
    >
      {cancellationPolicy.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CancellationPolicySelectFieldMaybe;
