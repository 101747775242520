import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { SmsVerificationFormNew, SmsVerificationFormPhoneUpdate } from '../../forms';
import store from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  NamedRedirect,
  IconArrowHead,
  NamedLink
} from '../../components';
import { withRouter } from 'react-router-dom';
import css from './SmsVerificationPage.css';
import { smsVerify, smsVerificationRequest } from '../../ducks/SmsVerification.duck';
import { saveContactDetails, saveContactDetailsClear } from '../ContactDetailsPage/ContactDetailsPage.duck';
import { ensureCurrentUser } from '../../util/data';

export class SmsVerificationPageComponent extends Component {
  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendSms = this.sendSms.bind(this);
        
    this.state = { 
      expiration: null,
      phoneWasChanged: false,
    };
  }

  tick = (expiration, second) => {
    if(expiration && expiration >= 1 ){
      this.setState({ expiration: expiration - second});
    }
  }

  componentDidMount(){
    // this.sendSms();
  }

  componentDidUpdate(prevProps){
    if(!!this.props.smsRequestData && this.props.smsRequestData !== prevProps.smsRequestData && !this.state.expiration){
      this.setState({ expiration: this.props.smsRequestData.seconds_to_expire});
      this.timerID = setInterval(() => this.tick(this.state.expiration, 1), 2000);
    }
    if(this.state.expiration === 0){
      clearInterval(this.timerID);
    }

    if(this.props.verificationRequestError !== prevProps.verificationRequestError){
      this.setState({
        error: this.props.verificationRequestError
      });
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.timerID);
  }

  sendSms = () => {
    const userId = localStorage.getItem('userId');
    this.props.onVerifyRequest(userId);
  }

  handleSubmit = (values) => {
    const token = values.token;
    const {onVerifyToken} = this.props;
    const userId = store.get('user').id;
    if(token && userId){
      const result = onVerifyToken(userId, token);
      Promise.resolve(result).then(result => {
        if(result){
          this.props.history.push('/');
        }
      })
    }
  };

  render(){
    const {
      intl,
      scrollingDisabled,
      verificationStatusError,
      verificationRequestError,
      onVerifyToken,
      history,
      phoneVerified,
      currentUser,
      smsRequestData,
      saveEmailError,
      savePhoneNumberError,
      saveContactDetailsInProgress,
      contactDetailsChanged,
      onChange,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      onSubmitContactDetails,
    } = this.props;


    const selectNextPage = (userType) => {
      return <NamedRedirect name="LandingPage" />;
    };

    // const smsVerificationForm = (
    //   <SmsVerificationForm
    //     onSubmit={handleSubmit}
    //     verificationStatusError={verificationStatusError}
    //     verificationRequestError={this.state.error}
    //     expiration={this.state.expiration}
    //   />
    // );

    const userTypeProps = get(this.props, 'currentUser.attributes.profile.privateData');
    const userType = userTypeProps !== undefined ? userTypeProps.user_type : '';

    const PrevIcon = props => (
      <IconArrowHead {...props} direction="left" rootClassName={css.arrowIcon} />
    );

    const privateData = get(this.props, 'currentUser.attributes.profile.privateData');
    const allowTwoFactorAuth = privateData !== undefined ? privateData.allowTwoFactorAuth : false;
    const title = intl.formatMessage({
      id: 'SmsVerificationPage.title',
    });

    const currentAltUser = store.get('user');
    const user = ensureCurrentUser(currentUser);
    const currentEmail = user.attributes.email || '';
    const protectedData = user.attributes.profile.protectedData || {};
    const currentPhoneNumber = protectedData.phoneNumber || '';

    console.log(onChange, 'onChange')

    const contactInfoForm = (
      <SmsVerificationFormPhoneUpdate
        className={css.form}
        initialValues={{ email: currentEmail, phoneNumber: currentPhoneNumber }}
        saveEmailError={saveEmailError}
        savePhoneNumberError={savePhoneNumberError}
        currentUser={currentUser}
        onResendVerificationEmail={onResendVerificationEmail}
        onSubmit={values => onSubmitContactDetails({ ...values, currentEmail, currentPhoneNumber, userId: currentAltUser.id })}
        onChange={onChange}
        inProgress={saveContactDetailsInProgress}
        ready={contactDetailsChanged}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
        phoneChanged={contactDetailsChanged}
      />
    );
    
    const smsVerificationForm = (
      <SmsVerificationFormNew
        onSubmit={!this.props.smsRequestData ? this.sendSms : this.handleSubmit}
        verificationStatusError={verificationStatusError}
        verificationRequestError={this.state.error}
        expiration={this.state.expiration}
        sendSms={this.sendSms}
        smsRequestData={this.props.smsRequestData}
        initialValues={{ phoneNumber: currentPhoneNumber }}
      />
    );

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled} className={css.layoutWrapperMain}>
        <div className={css.root}>
          <div className={css.content}>
            {
              !allowTwoFactorAuth && (
                  <NamedLink name="LandingPage" className={css.backButton}>
                    <PrevIcon width="15" height="19 " />
                    <FormattedMessage id="SmsVerificationPage.backBtn" />
                  </NamedLink>
              )
            }
            <h2 className={css.loginTitle}>
              <FormattedMessage id="SmsVerificationPage.smsVerification" />
            </h2>
            <p className={css.loginSubTitle}>
              <FormattedMessage id="SmsVerificationPage.subTitle" />
            </p>
            { this.state.expiration ? <span className={css.expiration}>The code expires in {this.state.expiration} seconds </span> : null }
            {contactInfoForm}
            {smsVerificationForm}
          </div>
        </div>
      </Page>
    )
  }
}

const { bool, func } = PropTypes;

SmsVerificationPageComponent.propTypes = {
  phoneVerified: bool.isRequired,
  scrollingDisabled: bool.isRequired,
  onVerifyToken: func.isRequired,
  onVerifyRequest: func.isRequired,

};

SmsVerificationPageComponent.defaultProps = {
  phoneVerified: false,
  scrollingDisabled: false,
  onVerifyToken: null,
  onVerifyRequest: null,
};

const mapStateToProps = state => {
  const { verificationStatusError, verificationRequestError, phoneVerified, smsRequestData } = state.smsVerification;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
    
  } = state.ContactDetailsPage;
  return {
    verificationStatusError,
    verificationRequestError,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    smsRequestData,
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onVerifyToken: (userId, token) => dispatch(smsVerify(userId, token)),
    onVerifyRequest: userId => dispatch(smsVerificationRequest(userId)),
    onChange: () => dispatch(saveContactDetailsClear()),
    onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
  }
}

const SmsVerificationPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl)(SmsVerificationPageComponent);

export default SmsVerificationPage;
