import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput, PrimaryButton } from '../../components';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';

import css from './SmsVerificationForm.css';

class SmsVerificationFormComponent extends Component {
  render() {
    const {
      intl,
    } = this.props;

    const verificationCodePlaceholder = intl.formatMessage({
      id: 'SmsVerificationForm.verificationCode',
    });
    const verificationCodeRequiredMessage = intl.formatMessage({
      id: 'SmsVerificationPage.codedRequired',
    });
    const verificationCodeRequired = validators.required(verificationCodeRequiredMessage);

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            handleSubmit,
            verificationStatusError,
            verificationRequestError,
          } = formRenderProps;

          const errorMesage = (
            <FormattedMessage id="SmsVerificationPage.incorrectVerificationCode" />
          )

          return (
            <Form className={css.root} onSubmit={handleSubmit}>
              { verificationRequestError && <p className={css.error}> {verificationRequestError} </p> }
              { this.props.expiration ? <span className={css.expiration}>The code expires in {this.props.expiration} seconds </span> : null }
              <div>
                <FieldTextInput
                  className={css.password}
                  type="password"
                  id="token"
                  name="token"
                  placeholder={verificationCodePlaceholder}
                  validate={verificationCodeRequired}
                />
              </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton type="submit">
                  <FormattedMessage id="SmsVerificationForm.smsVerify" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SmsVerificationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SmsVerificationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,
};

const SmsVerificationForm = compose(injectIntl)(SmsVerificationFormComponent);

SmsVerificationForm.displayName = 'SmsVerificationForm';

export default SmsVerificationForm;
