import React from 'react';
import { bool, func, shape, string, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldTextInput } from '../../components';
import CustomDurationSelectFieldMaybe from './CustomDurationSelectFieldMaybe';
import css from './EditListingPricingForm.css';

import { format } from '../../util/numberFormatter';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        duration,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        values,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        category,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: 'EditListingPricingForm.pricePerGuest',
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceOnline = new Money(config.listingMinimumPriceSubUnitsOnline, config.currency);
      const minPriceRequired =
        category !== 'online'
          ? validators.moneySubUnitAmountAtLeast(
              intl.formatMessage(
                {
                  id: 'EditListingPricingForm.priceTooLow',
                },
                {
                  minPrice: formatMoney(intl, minPrice),
                }
              ),
              config.listingMinimumPriceSubUnits
            )
          : validators.moneySubUnitAmountAtLeast(
              intl.formatMessage(
                {
                  id: 'EditListingPricingForm.priceTooLow',
                },
                {
                  minPrice: formatMoney(intl, minPriceOnline),
                }
              ),
              config.listingMinimumPriceSubUnitsOnline
            );
      const priceValidators =
        config.listingMinimumPriceSubUnits || config.listingMinimumPriceSubUnitsOnline
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const guestMinValue = values.numberOfGuestsFrom;
      const guestMaxValue = values.numberOfGuestsTo;

      const guestNumberValidValue = intl.formatMessage({
        id: 'EditListingPricingForm.guestValidNumber',
      });

      const guestsMinValueValidator = validators.guestsMinValueValidator(guestNumberValidValue, guestMaxValue);
      const guestsMaxValueValidator = validators.guestsMaxValueValidator(guestNumberValidValue, guestMinValue);

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          {/* <h2 className={css.subTitle}>
            <FormattedMessage id="EditListingPricingForm.numberOfGuestsTitle" />
          </h2> */}
          {/* <div className={css.numberOfGuestsWrapper}>
            <div className={css.numberOfGuestsBlock}>
              <p>
                <FormattedMessage id="EditListingPricingForm.numberOfGuestsFrom" />
              </p>
              <FieldTextInput
                id="numberOfGuestsFrom"
                name="numberOfGuestsFrom"
                className={css.numberOfGuestsInput}
                type="text"
                format={format}
                validate={guestsMinValueValidator}
              />
            </div>

            <div className={css.numberOfGuestsBlock}>
              <p>
                <FormattedMessage id="EditListingPricingForm.numberOfGuestsTo" />
              </p>
              <FieldTextInput
                id="numberOfGuestsTo"
                name="numberOfGuestsTo"
                className={css.numberOfGuestsInput}
                type="text"
                format={format}
                validate={guestsMaxValueValidator}
              />
            </div>
          </div> */}

          <h2 className={css.subTitle}>
            <FormattedMessage id="EditListingPricingForm.timeOfActivity" />
          </h2>
          <CustomDurationSelectFieldMaybe
            className={css.duration}
            id="duration"
            name="duration"
            duration={duration}
            intl={intl}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  duration: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
