import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Reviews } from '../../components';
import { ensureUser, ensureCurrentUser } from '../../util/data';

import css from './ListingPage.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError, filterRaitings } = props;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  return (
    <div id="reviews" className={css.sectionReviews}>
      <h2 className={css.reviewsHeading}>
        <FormattedMessage
          id="ListingPage.reviewsHeading"
          values={{ count: filterRaitings.length }}
        />
      </h2>
      {fetchReviewsError ? reviewsError : null}
      <Reviews reviews={reviews} ratings={filterRaitings} />
    </div>
  );
};

export default SectionReviews;
