import React, { Component, useState, useEffect } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, ensureCurrentUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { ListingFavouritesForm } from '../../forms';
import config from '../../config';
import {
  NamedLink,
  ResponsiveImage,
} from '..';

import addToFavoritesLinkImg from '../../assets/addToFavoritesBtnImg.svg';
import css from './ListingCard.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const [index, setIndex] = useState(0);
  const {
    className,
    rootClassName,
    intl, listing,
    renderSizes,
    setActiveListing,
    currentUser,
    onUpdateProfile,
    isAuthenticated,
    favoriteBtn,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.bookingUnitType;

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const save = async values => {
    await sleep(1000)
  };

  const user = ensureCurrentUser(currentUser);
  
  const listingFavourite =
  currentListing &&
  user.attributes.profile.publicData &&
  (user.attributes.profile.publicData.listingFavourite !== undefined)
  ? user.attributes.profile.publicData.listingFavourite : [];
  const init = listingFavourite ? listingFavourite.filter(l => l.listingId === id) : [];
  const listingImages = currentListing.images && currentListing.images.length > 0 ? currentListing.images[0].attributes.variants["landscape-crop"].url : null;
  const priceAmount = currentListing.attributes.price.amount;
  const priceCurrency = currentListing.attributes.price.currency;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const AddFavouriteButton = isAuthenticatedOrJustHydrated  ? (
    <ListingFavouritesForm
      className={css.checkbox}
      initialValues={{
        [`favourite_${id}`]: init[0] ? init[0][`favourite_${id}`] : false
      }}
      onSubmit={values => {
        save(values);
        let currentListingId = Object.keys(values)[0];
        let currentListingValue = values[`favourite_${id}`];
        currentListingId = currentListingId.replace('favourite_', '');
        const hasCurrentListing = listingFavourite.filter(l => l.listingId === currentListingId).length > 0;

        if (!hasCurrentListing && currentListingValue) {
          onUpdateProfile({
            publicData: {
              listingFavourite: [...listingFavourite, {
                title,
                authorName,
                priceAmount,
                priceCurrency,
                listingImages,
                listingId: id,
                [`favourite_${id}`]: true
              }]
            }
          });
        }
        if (hasCurrentListing && !currentListingValue) {
          const removeCurrentListing = listingFavourite.filter(l => l.listingId !== currentListingId);
          onUpdateProfile({
            publicData: {
              listingFavourite: removeCurrentListing
            }
          });
        }
      }}
      onChange={values => { }}
      listingId={id}
    />
  ) : 
  (
    <NamedLink className={css.favouriteButton + ' ' + css.favouriteImgLink} name="SignupPage">
      <img src={addToFavoritesLinkImg} alt="Add to favorites" />
    </NamedLink>
  );
  
  const showFavoriteBtn = favoriteBtn === false ? null : AddFavouriteButton;


  const authorNameLabel = authorName.length >= 25 ? authorName.slice(0,20)+ '..' : authorName;

  return (
    <div className={classes}>
      <div className={css.favouriteButton}>
        {showFavoriteBtn}
      </div>
      
      <NamedLink className={css.listingCardItem} name="ListingPage" params={{ id, slug }}>
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.aspectWrapper}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            />
          </div>
        </div>
        <div className={css.info}>
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.hostedBy" values={{ authorNameLabel }} />
            </div>
          </div>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.perUnit}>
              <FormattedMessage id="ListingCard.perGuest" />
            </div>
          </div>
        </div>
      </NamedLink>          

    </div >
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  isAuthenticated: bool.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
