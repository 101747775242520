import axios from 'axios';
import { storableError } from '../../util/errors';

import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
} from '../../util/transaction';

const API_URL = process.env.REACT_APP_API_NODE_URL ? process.env.REACT_APP_API_NODE_URL : '';

// ================ Action types ================ //

export const FETCH_TRANSACTIONS_REQUEST = 'app/MyCalendarPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/MyCalendarPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/MyCalendarPage/FETCH_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  transactions: [],
  queryTransactionsProgress: false,
  queryTransactionsError: null,
};

const manageMyCalendarPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        queryTransactionsProgress: true,
        queryTransactionsError: null,
        transactions: [],
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: payload.data,
        queryTransactionsProgress: false,
        queryTransactionsError: null,
      };
    case FETCH_TRANSACTIONS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryTransactionsProgress: false, queryTransactionsError: payload };

    default:
      return state;
  }
};

export default manageMyCalendarPageReducer;

// ================ Selectors ================ //

// ================ Action creators ================ //

export const queryTransactionsRequest = () => ({
  type: FETCH_TRANSACTIONS_REQUEST,
});

export const queryTransactionsSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: { data: response },
});

export const queryTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const queryOwnTransactions = (userId, pageParams) => async (dispatch, getState, sdk) => {
  const allTransactions = [];
  const currentUserId = userId.uuid;

  dispatch(queryTransactionsRequest());
  const result = await dispatch(getAllTransactions(currentUserId, pageParams));

  const transactions = result && result.response.data.data.data;
  const totalPages = result ? result.pages : 0;
  transactions && allTransactions.push(...transactions);

  for (let i = 2; i <= totalPages; i++) {
    const requestResult = await dispatch(getAllTransactions(currentUserId, i));
    const transactions = requestResult && requestResult.response.data.data.data;
    requestResult && allTransactions.push(...transactions);
  }

  const filterTransaction = allTransactions.filter(
    transaction =>
      transaction.attributes.lastTransition === TRANSITION_ACCEPT ||
      transaction.attributes.lastTransition === TRANSITION_CANCEL ||
      transaction.attributes.lastTransition === TRANSITION_COMPLETE
  );

  dispatch(queryTransactionsSuccess(filterTransaction));
};

export const getAllTransactions = (currentUserId, page) => dispatch => {
  const userType = 'provider';
  // const url = `api/user-transactions?userId=${currentUserId}&page=${page}&userType=${userType}`;
  const url = `${API_URL}/api/user-transactions?userId=${currentUserId}&page=${page}&userType=${userType}`; // Choose for localhost

  return axios
    .get(url)
    .then(response => {
      const totalPages = response.data.data.meta.totalPages;

      return { response: response, pages: totalPages };
    })
    .catch(e => {
      dispatch(queryTransactionsError(storableError(e)));
    });
};
