import React, { useState, useEffect } from 'react';
import { bool, func, array } from 'prop-types';

import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
} from '../../util/transaction';

import css from './ListingTable.css';

const ListingTable = props => {
  const { transactions, activelabelId } = props;

  const tabledataHeader = [
    '№',
    'Date',
    'Time',
    'Listing name',
    'Status',
    'Price/guest',
    'Guests',
    'In total',
  ];

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sortTransactions = transactions.sort((a, b) => {
    if (transactions && transactions.length > 0) {
      return +new Date(a.attributes.protectedData.start) >
        +new Date(b.attributes.protectedData.start)
        ? 1
        : +new Date(b.attributes.protectedData.start) > +new Date(a.attributes.protectedData.start)
        ? -1
        : 0;
    }
  });

  return (
    <div>
      {transactions && transactions.length > 0 ? (
        windowDimensions < 768 ? (
          <div className={css.cardMobile}>
            {sortTransactions.map((transaction, index) => {
              const price = (transaction.attributes.lineItems[0].unitPrice.amount / 100).toFixed(2);
              const seats = transaction.attributes.lineItems[0].seats;
              const units = parseInt(transaction.attributes.lineItems[0].units.value);
              const inTotal = (transaction.attributes.payinTotal.amount / 100).toFixed(2);
              const date = new Date(transaction.attributes.protectedData.start);
              const time = new Date(transaction.attributes.protectedData.start).toLocaleTimeString(
                'en-US',
                {
                  hour: '2-digit',
                  minute: '2-digit',
                }
              );
              const bookingDate = `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;
              const name = transaction.attributes.protectedData.listingName;
              const guest = seats;
              let status = '';

              switch (transaction.attributes.lastTransition) {
                case TRANSITION_ACCEPT:
                  status = 'Accepted';
                  break;
                case TRANSITION_CANCEL:
                  status = 'Canceled';
                  break;
                case TRANSITION_COMPLETE:
                  status = 'Completed';
                  break;

                default:
                  break;
              }

              const isActive = activelabelId ? activelabelId.uuid === transaction.id : false;

              return (
                <div
                  className={isActive ? css.cardContainerMobileActive : css.cardContainerMobile}
                  key={index}
                >
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[0]}</span>
                    <span className={css.itemSecondColumnMobile}>{index + 1}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[1]}</span>
                    <span className={css.itemSecondColumnMobile}>{bookingDate}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[2]}</span>
                    <span className={css.itemSecondColumnMobile}>{time}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[3]}</span>
                    <span className={css.itemSecondColumnMobile}>{name}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[4]}</span>
                    <span className={css.itemSecondColumnMobile}>{status}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[5]}</span>
                    <span className={css.itemSecondColumnMobile}>{`${price * units}$`}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[6]}</span>
                    <span className={css.itemSecondColumnMobile}>{guest}</span>
                  </div>
                  <div className={css.rowContainerMobile}>
                    <span className={css.itemFistColumnMobile}>{tabledataHeader[7]}</span>
                    <span className={css.itemSecondColumnMobile}>{`${inTotal}$`}</span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <table className={css.table}>
            <tbody>
              <tr className={css.tableHeadingRow}>
                {tabledataHeader.map((item, index) => {
                  return (
                    <th
                      className={index === 0 ? css.itemContainerFirst : css.itemContainer}
                      key={item}
                    >
                      <span className={css.itemHeader}>{item}</span>
                    </th>
                  );
                })}
              </tr>
              {sortTransactions.map((transaction, index) => {
                const price = (transaction.attributes.lineItems[0].unitPrice.amount / 100).toFixed(
                  2
                );
                const seats = transaction.attributes.lineItems[0].seats;
                const units = parseInt(transaction.attributes.lineItems[0].units.value);
                const inTotal = (transaction.attributes.payinTotal.amount / 100).toFixed(2);
                const date = new Date(transaction.attributes.protectedData.start);
                const time = new Date(
                  transaction.attributes.protectedData.start
                ).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                });
                const bookingDate = `${date.getMonth() +
                  1}.${date.getDate()}.${date.getFullYear()}`;
                const name = transaction.attributes.protectedData.listingName;
                const guest = seats;
                let status = '';

                switch (transaction.attributes.lastTransition) {
                  case TRANSITION_ACCEPT:
                    status = 'Accepted';
                    break;
                  case TRANSITION_CANCEL:
                    status = 'Canceled';
                    break;
                  case TRANSITION_COMPLETE:
                    status = 'Completed';
                    break;  

                  default:
                    break;
                }

                const isActive = activelabelId ? activelabelId.uuid === transaction.id : false;

                return (
                  <tr className={isActive ? css.tableDataRowActive : css.tableDataRow} key={index}>
                    <th className={css.itemContainerFirst}>
                      <span className={css.itemTable}>{index + 1}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{bookingDate}</span>
                    </th>
                    <th className={css.itemContainerThird}>
                      <span className={css.itemTable}>{time}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{name}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{status}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{`${price * units}$`}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{guest}</span>
                    </th>
                    <th className={css.itemContainer}>
                      <span className={css.itemTable}>{`${inTotal}$`}</span>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )
      ) : null}
    </div>
  );
};

ListingTable.propTypes = {
  editClick: func,
  deleteClick: func,
  confirmClick: func,
  transactions: array,
  disableButtonConfirm: bool,
};

export default ListingTable;
